/**
 * Created by vladislav on 29.04.20.
 */

var KeypadView = cc.Node.extend({
    avoidNode: "KeypadView",

    ctor: function (keypad) {
        this._super();

        this.keypad = keypad;

        var styles = cleverapps.styles.KeypadView;
        this.setAnchorPoint(0.5, 0.5);

        this.updateSize();

        this.radius = styles.radius[this.keypad.letters.length] || styles.radius.default;

        this.views = this.keypad.letters.map(function (letter) {
            var view = new KeypadLetterView(letter, this.radius);
            this.addChild(view);
            return view;
        }.bind(this));

        this.addControls();
        this.addShuffleButton();
        this.addPathNode();

        this.setVisible(false);

        this.createBackground();
        this.createSounds();
        this.setupChildren();
        this.setCascadeOpacityEnabledRecursively(true);

        keypad.on("push", this.onPush.bind(this), this);
        keypad.on("pop", this.onPop.bind(this), this);
        keypad.on("showUp", this.onShowUp.bind(this), this);
        keypad.on("force", this.showForce.bind(this), this);
        keypad.on("controlTypeChanged", this.onChangeControlType.bind(this), this);
        keypad.on("hide", this.animateHide.bind(this), this);
        keypad.on("grabFocus", this.grabFocus.bind(this), this);
        keypad.on("shuffle", this.shuffle.bind(this), this);
        keypad.on("tutorialStarted", function (letters, options) {
            this.tutorialWordLetters = letters;
            if (!this.currentTutorialLetterIndex) {
                this.currentTutorialLetterIndex = 0;
            }
            this.drawTutorialWord(this.tutorialWordLetters, options);
            this.shuffleButton.toggle(false);
        }.bind(this), this);

        keypad.on("tutorialStopped", function () {
            this.tutorialWordLetters = false;
            this.removeTutorialWord();
            this.shuffleButton.toggle(true);
        }.bind(this), this);

        keypad.on("status", function (status) {
            this.currentTutorialLetterIndex = 0;
            this.pathNode.animateFadeOut(0.3);
            if (status === WordGame.STATUSES.SHORT) {
                this.pathNode.reset();
            }
        }.bind(this), this);

        keypad.on("touchBegan", function () {
            this.removeTutorialWord();

            this.shuffleButton.toggle(false);
        }.bind(this), this);

        keypad.on("chooseLetter", this.createListener(this.chooseLetterAnimation.bind(this)), this);
        keypad.on("stopChooseLetter", this.createListener(this.stopChooseLetter.bind(this)), this);
        keypad.on("showMinimal", this.createListener(this.showMinimal.bind(this)), this);

        this.debugId = "Keypad";
    },

    shuffle: function () {
        var letterViews = this.keypad.letters.map(function (letter) {
            return letter.onLocateView();
        });

        cleverapps.UI.animateShuffle(letterViews);
    },

    setupChildren: function () {
        var styles = cleverapps.styles.KeypadView;

        var size = cleverapps.resolution.getSceneSize();
        this.setPositionRound(size.width / 2, this.height / 2);
        this.cancelButton.setPositionRound(cleverapps.resolution.mode === cleverapps.WideMode.VERTICAL ? styles.controls.cancel.xMobile : styles.controls.cancel.x, styles.controls.cancel.y);
        this.submitButton.setPositionRound(cleverapps.resolution.mode === cleverapps.WideMode.VERTICAL ? styles.controls.submit.xMobile : styles.controls.submit.x, styles.controls.submit.y);
    },

    updateSize: function () {
        var styles = cleverapps.styles.KeypadView;
        var scale = cleverapps.resolution.mode === cleverapps.WideMode.VERTICAL ? styles.mobileScale : styles.scale;
        this.setScale(scale);
        this.setContentSize2(styles.width, styles.height);
    },

    addPathNode: function () {
        this.pathNode = new cleverapps.Path(cleverapps.styles.COLORS.PATH_COLOR);
        this.addChild(this.pathNode);
        this.pathNode.setupChildren = function () {
            this.pathNode.reset();
            this.keypad.indexes.forEach(function (letter) {
                this.pathNode.push(letter.onLocateView().getPathPosition());
            }.bind(this));
        }.bind(this);
    },

    createSounds: function () {
        this.sounds = [];
        var soundId = 0;
        while (bundles.game.urls["nota_" + soundId]) {
            this.sounds.push(bundles.game.urls["nota_" + soundId]);
            soundId++;
        }
    },

    onPush: function (letter) {
        this.currentTutorialLetterIndex++;

        if (this.keypad.amountKeys() === 1) {
            this.pathNode.reset();
        }
        this.pathNode.push(letter.onLocateView().getPathPosition());

        cleverapps.audio.playSound(this.sounds[(this.keypad.indexes.length - 1) % this.sounds.length]);
    },

    onPop: function (silent) {
        this.currentTutorialLetterIndex = 0;
        this.pathNode.pop();

        if (this.keypad.amountKeys() === 0) {
            this.pathNode.reset();
        }

        if (!silent) {
            cleverapps.audio.playSound(this.sounds[this.keypad.indexes.length % this.sounds.length]);
        }
    },

    showForce: function (f, force) {
        if (cleverapps.forces.isAvailable(this, force)) {
            this.shuffleButton.toggle(false);
            cleverapps.forces.create(this, Forces.KEYPAD);
            cleverapps.forces.onceForceClosed = f;
        } else {
            f();
        }
    },

    animateHide: function (f) {
        this.runAction(new cc.Spawn(
            new cc.FadeOut(0.3),
            new cc.Sequence(
                new cc.PlaySound(bundles.game.urls.keypad_hide),
                new cc.ScaleTo(0.3, 0.5).easing(cc.easeBackIn()),
                new cc.CallFunc(f),
                new cc.RemoveSelf()
            )
        ));
    },

    onShowUp: function (f, silent) {
        if (this.isVisible() && !cleverapps.silentIntro) {
            f();
            return;
        }

        var callback = function () {
            cleverapps.UI.onDrag(this, {
                instantDrag: true,
                onDragStart: this.onTouchBegan.bind(this),
                followPointer: this.onTouchMoved.bind(this),
                onDragEnd: this.onTouchEnded.bind(this)
            });

            f();
        }.bind(this);

        if (cleverapps.gameModes.noKeypad) {
            callback();
            return;
        }

        if (silent) {
            this.setVisible(true);
            callback();
            return;
        }

        this.setOpacity(0);

        this.runAction(new cc.Spawn(
            new cc.FadeIn(0.3),
            new cc.Sequence(
                new cc.ScaleTo(0, 0.5),
                new cc.Show(),
                new cc.ScaleTo(0.3, this.getScale()).easing(cc.easeBackOut()),
                new cc.CallFunc(callback)
            )
        ));
    },

    removeTutorialWord: function () {
        FingerView.remove(this.finger);
        this.finger = undefined;
    },

    drawTutorialWord: function (letters, options) {
        if (FingerView.isRunning(this.finger)) {
            return;
        }

        options = options || {};

        var path = letters.map(function (letter) {
            return letter.onLocateView();
        });

        this.finger = FingerView.hintDrag(path, {
            callback: options.callback,
            runOnce: options.looped === undefined ? false : !options.looped,
            pathColor: cleverapps.styles.COLORS.PATH_COLOR
        });
    },

    calcLetterTouched: function (touch) {
        for (var i = 0; i < this.keypad.letters.length; i++) {
            if (!this.keypad.letters[i].onLocateView()) {
                var views = this.children.filter(function (child) {
                    return child instanceof KeypadLetterView;
                });

                throw new Error("No letter view keypad.stopListeners: " + this.stopListeners + " letter.stopListener: " + (views[i] ? views[i].stopListeners : "noView")
                    + " gameOutcome: " + this.keypad.game.outcome + " viewsLength: " + views.length + " modelsLength: " + this.keypad.letters.length);
            }

            if (this.tutorialWordLetters) {
                if (this.tutorialWordLetters[this.currentTutorialLetterIndex] && this.tutorialWordLetters[this.currentTutorialLetterIndex].onLocateView().isTouched(touch)) {
                    return this.tutorialWordLetters[this.currentTutorialLetterIndex];
                }
            } else if (this.keypad.letters[i].onLocateView().isTouched(touch)) {
                return this.keypad.letters[i];
            }
        }

        return undefined;
    },

    addControls: function () {
        var styles = cleverapps.styles.KeypadView;

        this.cancelButton = new cleverapps.UI.Button({
            content: new cc.Sprite(bundles.game.frames.cancel_icon_png),
            onClicked: function () {
                this.keypad.cancel();
            }.bind(this),
            width: styles.controls.width,
            height: styles.controls.height
        });

        this.cancelButton.avoidNode = "KeypadView";

        this.submitButton = new cleverapps.UI.Button({
            content: new cc.Sprite(bundles.game.frames.submit_icon_png),
            onClicked: function () {
                this.keypad.submit();
            }.bind(this),
            width: styles.controls.width,
            height: styles.controls.height
        });

        this.submitButton.avoidNode = "KeypadView";

        var updateControlSize = function () {
            var styles = cleverapps.styles.KeypadView;
            var scale = 1;
            if (cleverapps.resolution.mode === cleverapps.WideMode.VERTICAL) {
                scale = styles.controls.mobileScale;
            }
            this.baseScale = scale;
            this.setScale(scale);
        };

        this.cancelButton.updateSize = updateControlSize.bind(this.cancelButton);
        this.submitButton.updateSize = updateControlSize.bind(this.submitButton);

        this.cancelButton.updateSize();
        this.submitButton.updateSize();

        this.cancelButton.setVisible(false);
        this.submitButton.setVisible(false);

        if (!cleverapps.gameModes.hideKeypadControls) {
            this.addChild(this.cancelButton);
            this.addChild(this.submitButton);
        }
    },

    createBackground: function () {
        var styles = cleverapps.styles.KeypadView;

        if (!styles.bg) {
            return;
        }

        var bg = this.background = new cc.Sprite(bundles.game.frames.keypad_bg_png);
        bg.setLocalZOrder(-10);
        this.addChild(bg);

        bg.setupChildren = function () {
            bg.setPositionRound(this.width / 2, this.height / 2);
        }.bind(this);

        bg.updateSize = function () {
            bg.setScale((2 * this.radius + styles.bg.diameter) / bg.width);
        }.bind(this);

        bg.setupChildren();
        bg.updateSize();
    },

    onChangeControlType: function (controlType) {
        if (controlType === Keypad.CONTROL_TYPES.CLICK) {
            this.cancelButton.setVisible(true);
            this.submitButton.setVisible(true);
            this.cancelButton.setScale(0.5);
            this.cancelButton.runAction(new cc.ScaleTo(0.2, this.cancelButton.baseScale));
            this.submitButton.setScale(0.5);
            this.submitButton.runAction(new cc.ScaleTo(0.2, this.submitButton.baseScale));
        } else {
            this.cancelButton.setVisible(false);
            this.submitButton.setVisible(false);
        }

        if (!FingerView.isRunning(this.finger) || cleverapps.config.wysiwygMode) {
            this.shuffleButton.toggle(controlType === Keypad.CONTROL_TYPES.UNDEFINED);
        }
    },

    addShuffleButton: function () {
        this.shuffleButton = new ShuffleButton(this.keypad);
        this.addChild(this.shuffleButton);
    },

    onTouchBegan: function (touch) {
        this.beginPoint = touch.getLocation();

        return this.keypad.touchBegan(this.calcLetterTouched(touch));
    },

    onTouchMoved: function (touch) {
        var styles = cleverapps.styles.KeypadView;

        var endPoint = touch.getLocation();
        if (Math.abs(endPoint.x - this.beginPoint.x) + Math.abs(endPoint.y - this.beginPoint.y) > styles.moveEps) {
            this.keypad.swipe();
        }

        if (this.keypad.controlType === Keypad.CONTROL_TYPES.MOVE) {
            this.pathNode.draw(this.convertTouchToNodeSpace(touch));
        }

        var letter = this.calcLetterTouched(touch);
        if (letter) {
            this.keypad.letterTouched(letter);
        }
    },

    onTouchEnded: function () {
        this.pathNode.draw();
        this.keypad.touchEnded();
    },

    grabFocus: function () {
        this.tutorialWordLetters = false;
        this.removeTutorialWord();
        cleverapps.focusManager.setEventNodes([this]);
    },

    showMinimal: function (text) {
        this.hideMinimal();

        var dialogue = this.dialogue = new MinimalDialogue({
            delay: 0,
            text: text,
            rects: [this.getSceneBoundingBox()]
        });
        cleverapps.scenes.getRunningScene().addChild(dialogue);
        dialogue.display();
    },

    hideMinimal: function () {
        if (this.dialogue) {
            this.dialogue.removeFromParent();
        }
    },

    stopChooseLetter: function () {
        this.views.forEach(function (letter) {
            letter.stopAllActions();
        });

        this.hideMinimal();
    },

    chooseLetterAnimation: function (message) {
        var lettersIndexes = this.views.map(function (letter, index) {
            return {
                index: index,
                x: letter.x,
                y: letter.y
            };
        }).sort(function (letter1, letter2) {
            if (letter1.y !== letter2.y) {
                return letter1.y - letter2.y;
            }
            return letter1.x - letter2.x;
        });

        var fullDuration = Math.floor(this.views.length / 2) * 0.2 + 1.5;
        lettersIndexes.forEach(function (letter, index) {
            var pairIndex = this.views.length % 2 === 0 ? Math.floor((index + 1) / 2) : Math.floor(index / 2);
            this.views[letter.index].chooseLetterAnimation(pairIndex * 0.2, fullDuration);
        }.bind(this));

        this.showMinimal(message);
        this.grabFocus();
    }
});

cleverapps.styles.KeypadView = {
    width: 600,
    height: 600,
    scale: 1,
    mobileScale: 0.85,
    moveEps: 30,

    radius: {
        default: 215,
        "3": 160,
        "4": 170,
        "5": 185,
        "6": 200
    },

    controls: {
        width: 180,
        height: 120,

        mobileScale: 1.0,

        submit: {
            x: { align: "center", dx: 400 },
            xMobile: { align: "center", dx: 300 },
            y: { align: "bottom", dy: 70 }
        },

        cancel: {
            x: { align: "center", dx: -400 },
            xMobile: { align: "center", dx: -300 },
            y: { align: "bottom", dy: 70 }
        }
    },

    chooseLetter: {
        animation: {
            x: { align: "center" },
            y: { align: "center" }
        },

        finger: {
            x: { align: "center" },
            y: { align: "center" }
        }
    }
};
