/**
 * Created by evgeny on 26.04.2024
 */

RewardsConfig.Butterfly = [
    {
        hard: 50
    },
    {
        hard: 50
    },
    {
        hard: 100
    }
];

Mission.TYPE_BUTTERFLY = 64;

Missions.FEATURES[Mission.TYPE_BUTTERFLY] = function () {
    Missions[Mission.TYPE_BUTTERFLY] = {
        available: {
            level: 26
        },
        name: "Butterfly",
        prize: RewardsConfig.Butterfly,
        collectIcon: bundles.butterfly_game.frames.reward,
        semaphore: Missions.SEMAPHORE_SECONDARY,
        startWindow: ButterflyWindow,
        duration: "3 days",
        view: ButterflyWindow,
        bundle: "butterfly",
        sideBarJson: bundles.sidebar.jsons.butterfly_sidebar_icon,
        logic: ButterflyLogic
    };
};