/**
 * Created by Vladislav on 25.09.2024.
 */

var CryptexMenuBarItem = {
    name: "CryptexItem",
    centerAlign: true,
    helpButton: true,
    force: Forces.CRYPTEX,
    icon: bundles.menubar.frames.cryptex_png,
    onClickWindow: CryptexWindow,
    modelClass: CryptexMenuBarItemModel,
    viewClass: CryptexMenuBarItemView,
    availableOnScenes: function () {
        return Game.currentGame && Game.currentGame.cryptex && [cleverapps.Environment.SCENE_GAME];
    }
};