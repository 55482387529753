/**
 * Created by andrey on 14.03.18.
 */

var HintButtonBooster = BaseButtonBooster.extend({
    ctor: function (booster) {
        this._super({
            booster: booster,
            styles: cleverapps.styles.HintButtonBooster
        });

        this.setLocalZOrder(cleverapps.styles.HintButtonBooster.zOrder);
    }
});

cleverapps.styles.HintButtonBooster = {
    width: 250,
    height: 110,
    zOrder: 0,

    position: [
        { x: { align: "right", dx: -10 }, y: { align: "top", dy: -730 } },
        { x: { align: "right", dx: -10 }, y: { align: "top", dy: -730 } },
        { x: { align: "right", dx: -10 }, y: { align: "top", dy: -730 } }
    ],

    phone: {
        scale: 0.8
    },

    content: {
        margin: 10,
        direction: cleverapps.UI.HORIZONTAL,
        freeBlock: {
            textWidth: 120
        },
        layout: {
            x: { align: "center", dx: 0 },
            y: { align: "center", dy: 0 }
        }
    }
};