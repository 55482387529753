/**
 * Created by vladislav on 05.03.2020
 */

var CurrentLetterView = cc.Node.extend({
    ctor: function (char) {
        this._super();
        this.char = char;
        this.wasFlying = false;
        this.setAnchorPoint(0.5, 0.5);

        var styles = cleverapps.styles.CurrentLetterView;

        var noBg = ["scramble", "olympics"].indexOf(cleverapps.config.name) !== -1;
        this.charView = CharView.Create(char, {
            noBg: noBg,
            bgImage: CurrentLetterView.bgImage
        });
        this.addChild(this.charView);
        this.setContentSize2(this.charView.getContentSize());
        this.charView.setPositionRound(this.width / 2, this.height / 2);

        this.setScale(styles.scale);
        this.baseScale = this.scale;

        this.setCascadeColorEnabled(true);
        this.setCascadeOpacityEnabled(true);
    },

    isFlying: function () {
        return this.wasFlying;
    },

    setFlying: function (flying) {
        this.wasFlying = flying;
    },

    showUp: function () {
        this.setOpacity(0);
        this.setScale(0.6 * this.baseScale);
        this.runAction(new cc.Spawn(
            new cc.FadeIn(0.2).easing(cc.easeIn(3)),
            new cc.ScaleTo(0.2, this.baseScale).easing(cc.easeIn(3))
        ));
    }
});

cleverapps.styles.CurrentLetterView = {
    scale: 1.0
};