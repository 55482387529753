/**
 * Created by razial on 19.01.2023
 */

cleverapps.UI.animateHintFly = function (target, options) {
    var scene = cleverapps.scenes.getRunningScene();
    options = options || {};

    var animation = new cleverapps.Spine(bundles.game.jsons.hint_json);
    animation.setAnimation(0, "animation", false);
    animation.setCompleteListener(function () {
        animation.setAnimation(0, "idle", false);
        animation.setCompleteListenerRemove();
    });
    scene.addChild(animation);

    var targetPos = animation.getRelativeCoordinates(target);

    var startPos;
    if (options.startPosition) {
        startPos = options.startPosition;
    } else {
        startPos = animation.getRelativeCoordinates(options.start ? options.start : cleverapps.aims.getTarget("boosters" + cleverapps.Boosters.TYPE_HINT));
    }

    var duration = options.duration || 0.3;
    var distance = cc.pDistance(startPos, targetPos);
    var timescale = Math.min(1, distance / duration / cleverapps.styles.FlyHintAnimation.speed);

    animation.setTimeScale(timescale);
    animation.setLocalZOrder(10);
    animation.setPositionRound(startPos);
    animation.setRotation(Math.atan2(targetPos.x - startPos.x, targetPos.y - startPos.y) * 180 / Math.PI);

    animation.runAction(new cc.Sequence(
        new cc.PlaySound(bundles.game.urls.hint_effect),
        new cc.MoveTo(duration, targetPos),
        new cc.CallFunc(function () {
            animation.setTimeScale(1);
            animation.replaceParentSamePlace(target, {
                keepScale: true
            });
        })
    ));
};

cleverapps.UI.animateLetterHintFly = function (target, letterSprite, options) {
    options = options || {};
    cleverapps.scenes.getMovingNode(target).addChild(letterSprite);
    letterSprite.setScale(1 / letterSprite.adjustScaleTo(target).x);
    var shine = new cleverapps.Spine(bundles.game.jsons.hint_json);
    shine.setAnimation(0, "idle", false);
    shine.setVisible(false);
    shine.setAnchorPoint(0.5, 0.5);
    shine.setPositionRound(letterSprite.width / 2, letterSprite.height / 2);
    shine.setTimeScale(0);
    letterSprite.addChild(shine);

    var targetPos = letterSprite.getRelativeCoordinates(target);
    var startPos = letterSprite.getRelativeCoordinates(options.start ? options.start : cleverapps.aims.getTarget("boosters" + cleverapps.Boosters.TYPE_HINT));

    letterSprite.setPositionRound(startPos);

    letterSprite.runAction(new cc.Sequence(
        new cc.PlaySound(bundles.game.urls.hint_letter_effect),
        new cc.Spawn(
            new cc.Sequence(
                new cc.MoveTo(0.375, targetPos),
                new cc.ReplaceParent(target)
            ),
            new cc.Sequence(
                new cc.RotateTo(0.2, 30).easing(cc.easeOut(1)),
                new cc.RotateTo(0.16, 0).easing(cc.easeIn(1)),
                new cc.Spawn(
                    new cc.CallFunc(function () {
                        shine.setVisible(true);
                        shine.setTimeScale(1);
                    }),
                    new cc.RotateTo(0.14, -30).easing(cc.easeIn(1))
                ),
                new cc.RotateTo(0.125, 0)
            )
        )
    ));
};

cleverapps.styles.FlyHintAnimation = {
    speed: 3500
};