/**
 * Created by slava on 23.03.17.
 */

var CharView = {
    Create: function (symbol, options) {
        this.options = options = options || {};

        var styles = cleverapps.styles.CharView;

        var bg;
        if (options.noBg) {
            bg = new cc.Node();
            bg.setContentSize2(styles);
            bg.setAnchorPoint(0.5, 0.5);
        } else {
            bg = new cc.Sprite(CharView.GetImage(options));
        }

        if (["ss", "SS", "ß"].indexOf(symbol) !== -1) {
            symbol = "ß";
        }

        var charView = CharView.GetView(symbol);
        charView.setPosition(bg.width / 2, bg.height / 2 + CharView.CalcCharPadding(options));
        charView.setLocalZOrder(2);
        bg.addChild(charView);

        bg.setCascadeColorEnabled(true);
        bg.setCascadeOpacityEnabled(true);

        return bg;
    },

    CalcCharPadding: function (options) {
        var styles = cleverapps.styles.CharView;

        if (options.noBg) {
            return styles.topPadding;
        }
        return styles.topPaddingWithBg;
    },

    GetImage: function (options) {
        if (options.bgImage) {
            return options.bgImage;
        }

        return bundles.letters.frames.letter_bg_png;
    },

    GetView: function (symbol) {
        var bundleName = (cleverapps.settings.language === cc.sys.LANGUAGE_JAPANESE) ? "letters_ja" : "field_letters";

        if (this.options.whiteLetter && Object.keys(bundles.keypad_letters.frames).length > 0) {
            bundleName = "keypad_letters";

            if (cleverapps.settings.language === cc.sys.LANGUAGE_JAPANESE) {
                bundleName = "keypad_letters_ja";
            }
        }

        if (cleverapps.bundleLoader.isLoaded(bundleName)) {
            var bundle = bundles[bundleName];

            var lowerCaseChar = symbol.toLowerCase();
            var framePrefix = "";

            if (this.options.whiteLetter && bundle.frames["keypad_letter_" + lowerCaseChar]) {
                framePrefix += "keypad_";
            }

            framePrefix += "letter_";
            if (bundle.frames[framePrefix + lowerCaseChar]) {
                return new cc.Sprite(bundle.frames[framePrefix + lowerCaseChar]);
            }
        }

        return cleverapps.UI.generateTTFText(symbol, cleverapps.styles.FONTS.LETTERS_TEXT);
    }
};

cleverapps.styles.CharView = {
    width: 92,
    height: 92,
    topPadding: 0,
    topPaddingWithBg: 0
};