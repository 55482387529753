/**
 * Created by evgeny on 03.05.2024
 */

var ButterflyLogic = function (mission) {
    this.mission = mission;
    this.load(this.mission.details);

    this.lastShownStage = this.stage;
    this.lastShownResult = this.getCurrentStageResult();
};

ButterflyLogic.prototype.getCurrentStageResult = function () {
    var result = this.mission.result;
    for (var i = 1; i < this.stage; i++) {
        result -= this.getGoal(i);
    }
    return Math.min(result, this.getGoal());
};

ButterflyLogic.prototype.onProgressBarFill = function () {
    this.lastShownResult = this.getCurrentStageResult();
};

ButterflyLogic.prototype.onProgressBarReload = function () {
    this.rewardReseived = false;
    this.lastShownStage = this.stage;
    this.lastShownResult = 0;
};

ButterflyLogic.prototype.updateState = function () {
    if (this.rewardReseived) {
        this.stage++;
        this.saveProgress();

        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.BUTTERFLY_LVLUP);
    }
};

ButterflyLogic.prototype.claimReward = function () {
    var reward = this.mission.receiveReward();
    RewardWindow.createMissionWindow(reward, this.mission);
};

ButterflyLogic.prototype.getPrizeSpine = function () {
    return bundles.butterfly_window.jsons["prize_" + this.stage];
};

ButterflyLogic.prototype.load = function (data) {
    this.stage = data.stage || 1;
};

ButterflyLogic.prototype.saveProgress = function () {
    this.mission.update(0, this.getInfo());
};

ButterflyLogic.prototype.getInfo = function () {
    return {
        stage: this.stage
    };
};

ButterflyLogic.prototype.getGoal = function (stage) {
    return ButterflyLogic.GOALS[(stage || this.stage) - 1];
};

ButterflyLogic.prototype.isProgressFull = function () {
    return this.getCurrentStageResult() >= this.getGoal();
};

ButterflyLogic.prototype.getPrize = function () {
    return this.mission.prize[this.stage - 1] || this.mission.prize[0];
};

ButterflyLogic.prototype.receiveReward = function () {
    if (this.rewardReseived || this.getCurrentStageResult() < this.getGoal()) {
        this.mission.rewardsReceived = true;
        return;
    }

    this.rewardReseived = true;
    return this.getPrize();
};

ButterflyLogic.prototype.canRemove = function () {
    return this.rewardReseived || this.mission.getTimeLeft() <= 0;
};

ButterflyLogic.prototype.isReadyToFinish = function () {
    return this.isProgressFull() && this.stage > ButterflyLogic.STAGES_COUNT - 1 && this.mission.isRunning();
};

ButterflyLogic.prototype.processEvent = function (options) {
    this.mission.result += options.amount;
};

ButterflyLogic.prototype.wantToShowNewState = function () {
    return this.isProgressFull() && this.mission.isRunning();
};

ButterflyLogic.prototype.getCollectRewardSound = function () {
    return bundles.butterfly_game.urls.reward_effect;
};

ButterflyLogic.prototype.getButtonText = function () {
    if (this.isProgressFull()) {
        return Messages.get("Claim");
    }

    return Messages.get("Play");
};

ButterflyLogic.prototype.onComplete = function () {
    cleverapps.eventLogger.logEvent(cleverapps.EVENTS.BUTTERFLY_END_COLLECTED + this.mission.result);
    cleverapps.eventLogger.logEvent(cleverapps.EVENTS.BUTTERFLY_END_STAGE + this.stage);
};

ButterflyLogic.GOALS = [30, 50, 100];

ButterflyLogic.STAGES_COUNT = 3;

ButterflyLogic.PROGRESS_BAR_FIRST_STAGE = 1;
ButterflyLogic.PROGRESS_BAR_NEW_STAGE = 2;
ButterflyLogic.PROGRESS_BAR_CURRENT_STAGE = 3;
ButterflyLogic.PROGRESS_BAR_FILL = 4;
ButterflyLogic.PROGRESS_BAR_FULL = 5;
ButterflyLogic.PROGRESS_BAR_RELOAD = 6;