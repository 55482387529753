/**
 * Created by rvvslv on 13.01.21.
 */

var CryptexMenuBarItemView = MenuBarItemView.extend({
    ctor: function (item) {
        this._super(item);
    },

    addText: function () {},

    reset: function () {
        this._super();

        this.timerView = new cleverapps.CountDownView(this.item.timer, {
            font: cleverapps.styles.FONTS.MENUBAR_TEXT,
            showHours: false
        });
        this.addChild(this.timerView);
        this.timerView.setPositionRound(this.getTextPosition());
    }
});