/**
 * Created by vladislav on 09.06.20.
 */

var ShuffleButton = cleverapps.UI.Button.extend({
    avoidNode: "KeypadView",
    ctor: function (keypad) {
        this.keypad = keypad;

        this._super({
            type: {
                button_png: bundles.game.frames.shuffle_png,
                button_on_png: bundles.game.frames.shuffle_on_png
            },
            onClicked: this.onClicked.bind(this)
        });

        this.setupChildren();

        this.setCascadeOpacityEnabled(true);
    },

    setupChildren: function () {
        this.setPositionRound(cleverapps.styles.ShuffleButton);
    },

    onClicked: function () {
        this.keypad.shuffle();
        cleverapps.audio.playSound(bundles.game.urls.shuffle_button_effect);
    },

    hide: function () {
        this.toggle(false);
        this._hidden = true;
    },

    show: function () {
        this._hidden = false;
        this.toggle(true);
    },

    toggle: function (visible) {
        if (this._hidden) {
            return;
        }

        this.clickHandler.setEnabled(visible);

        this.stopAllActions();
        if (visible) {
            this.runAction(new cc.Sequence(
                new cc.DelayTime(0.3),
                new cc.Show(),
                new cc.FadeTo(0.2, 255)
            ));
        } else {
            this.runAction(new cc.Sequence(
                new cc.FadeOut(0.1),
                new cc.Hide()
            ));
        }
    }
});

cleverapps.styles.ShuffleButton = {
    x: { align: "center" },
    y: { align: "center" }
};