/**
 * Created by andrey on 29.01.18.
 */

cleverapps.styles.COLORS = cleverapps.overrideColors(cleverapps.styles.COLORS, {
    PLAYER_ALARM_COLOR: new cc.Color(160, 160, 160, 255),
    HINT_ALARM_COLOR: new cc.Color(150, 150, 150, 255)
});

cleverapps.styles.FONTS = cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    CONGRATE_TEXT: {
        size: 100,
        color: cleverapps.styles.COLORS.WHITE
    }
});

if (cleverapps.styles.PagingScrollView) {
    cleverapps.overrideStyles(cleverapps.styles.PagingScrollView, {
        position: {
            x: { align: "center" },
            y: { align: "center" }
        }
    });
}

cleverapps.overrideStyles(cleverapps.styles.RestoreProgressButton, {
    size: [
        { width: 180, height: 70 },
        { width: 300, height: 110 },
        { width: 300, height: 110 }
    ],

    position: [
        {
            x: { align: "right", dx: -20 },
            y: { align: "bottom", dy: 20 }
        },
        {
            x: { align: "left", dx: 20 },
            y: { align: "bottom", dy: 20 }
        },
        {
            x: { align: "center", dx: -50 },
            y: { align: "bottom", dy: 10 }
        },
        {
            x: { align: "left", dx: 20 },
            y: { align: "center", dy: -150 }
        },
        {
            x: { align: "right", dx: -20 },
            y: { align: "top", dy: -110 }
        }
    ]
});