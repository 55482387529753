/**
 * Created by slava on 24.03.17.
 */

cleverapps.Current = function (game, keypad) {
    cleverapps.EventEmitter.call(this);

    this.game = game;
    this.letters = [];
    this.lastWord = [];
    this.keypad = keypad;

    keypad.on("push", this.push.bind(this));
    keypad.on("pop", this.pop.bind(this));
    keypad.on("reset", this.clear.bind(this));
    this.onGetLetterView = function () {};
};

cleverapps.Current.prototype = Object.create(cleverapps.EventEmitter.prototype);
cleverapps.Current.prototype.constructor = cleverapps.Current;

cleverapps.Current.prototype.finishControl = function () {
    if (this.keypad.onceControlFinished) {
        this.keypad.onceControlFinished();
    }
};

cleverapps.Current.prototype.status = function (status, word) {
    this.letters = [];

    if ([WordGame.STATUSES.EXTRA, WordGame.STATUSES.EXTRA_FOUND].includes(status)) {
        this.lastWord = word.split("");
    }

    var notification;
    switch (status) {
        case WordGame.STATUSES.WRONG:
            cleverapps.audio.playSound(bundles.game.urls.wrong_effect);
            this.trigger("wrong", bundles.game.frames.word_wrong_png, this.finishControl.bind(this));
            notification = "CurrentView.Wrong";
            break;
        case WordGame.STATUSES.BOARD:
            this.trigger("right", undefined, this.finishControl.bind(this));
            break;
        case WordGame.STATUSES.BOARD_FOUND:
            cleverapps.audio.playSound(bundles.game.urls.alreadyfound_effect);
            this.trigger("right", undefined, this.finishControl.bind(this));
            notification = "CurrentView.BoardFound";
            break;
        case WordGame.STATUSES.EXTRA:
            cleverapps.audio.playSound(bundles.game.urls.extra_effect);
            this.trigger("right", undefined, this.finishControl.bind(this));
            break;
        case WordGame.STATUSES.EXTRA_FOUND:
            cleverapps.audio.playSound(bundles.game.urls.alreadyfound_effect);
            this.trigger("wrong", undefined, this.finishControl.bind(this));
            notification = "CurrentView.ExtraFound";
            break;
        case WordGame.STATUSES.SHORT:
            cleverapps.audio.playSound(bundles.game.urls.wrong_effect);
            this.trigger("wrong", bundles.game.frames.word_wrong_png, this.finishControl.bind(this));
            notification = "CurrentView.Short";
            break;
    }

    if (notification) {
        cleverapps.notification.create(notification, {
            toReplace: { word: word.toUpperCase() }
        });
    }
};

cleverapps.Current.prototype.push = function (letter, silent) {
    if (this.letters.length === 0) {
        this.trigger("reset");
    }

    this.letters.push(letter.symbol);
    this.trigger("push", letter.symbol, silent);
};

cleverapps.Current.prototype.pop = function () {
    if (this.letters.length > 0) {
        this.letters.pop();
        this.trigger("pop");
    }
};

cleverapps.Current.prototype.clear = function () {
    this.letters = [];
    this.trigger("clear", this.finishControl.bind(this));
};

cleverapps.Current.prototype.getIndex = function (letter) {
    return this.lastWord.indexOf(letter);
};

cleverapps.Current.prototype.getLetterView = function (letter) {
    return this.onGetLetterView(letter);
};