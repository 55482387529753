/**
 * Created by andrey on 19.01.18.
 */

var CryptexView = cc.Node.extend({
    ctor: function (cryptex) {
        this._super();
        this.cryptex = cryptex;

        this.setAnchorPoint(0.5, 0.5);
        this.setVisible(false);

        this.animation = new cleverapps.Spine(bundles.cryptex.jsons.cryptex_json);
        this.addChild(this.animation);

        this.setupChildren();

        this.cryptex.onAttempt = this.createListener(this.onAttempt.bind(this));
        this.cryptex.onFail = this.createListener(this.onFail.bind(this));
        this.cryptex.onOpen = this.createListener(this.onOpen.bind(this));
    },

    setupChildren: function () {
        var styles = cleverapps.styles.CryptexView;
        this.setPositionRound(styles.position);
    },

    cryptexAnimation: function (animation, callback) {
        this.setVisible(true);
        this.animation.setAnimation(0, animation, false);
        this.animation.setCompleteListener(function () {
            this.setVisible(false);
            if (callback) {
                callback();
            }
        }.bind(this));
    },

    onAttempt: function () {
        this.cryptexAnimation("attempt");
        cleverapps.audio.playSound(bundles.game.urls.cryptex_attempt_effect);
    },

    onFail: function () {
        this.cryptexAnimation("fail");
        cleverapps.audio.playSound(bundles.game.urls.cryptex_fail_effect);
    },

    onOpen: function (f) {
        this.cryptexAnimation("open", f);
        cleverapps.audio.playSound(bundles.game.urls.cryptex_open_effect);

        this.runAction(new cc.Sequence(
            new cc.DelayTime(2),
            AnimationsLibrary.coinCloud(this, {
                reward: this.cryptex.getReward().getValue(),
                cloudSize: CoinCloud.MEDIUM
            })
        ));
    }
});

cleverapps.styles.CryptexView = {
    position: {
        x: { align: "center", dx: 0 },
        y: { align: "top", dy: -220 }
    }
};