/**
 * Created by Andrey Popov on 26.03.20
 */

var CookieJarView = cleverapps.Spine.extend({
    avoidNode: "CookieJarView",

    ctor: function (extra, parent) {
        this._super(bundles.cookiejar.jsons.extra_container_json);

        this.extra = extra;
        parent.addChild(this);

        cleverapps.UI.applyHover(this);
        cleverapps.UI.onClick(this, this.onClick.bind(this));

        var styles = cleverapps.styles.CookieJarView;

        this.setAnimation(0, "idle", true);
        this.setAnchorPoint(0.5, 0.5);

        this.updateSize();

        this.setPositionRound(styles.positions);

        if (levels.cookieJar.visible) {
            this.makeControl();
        } else {
            this.setVisible(false);
        }

        cleverapps.aims.registerTarget("cookie", this, {
            controls: ["cookieJar"]
        });

        this.setLocalZOrder(0);

        levels.cookieJar.onCookieListener = this.createListener(this.addCookie.bind(this));
        levels.cookieJar.onFullListener = this.createListener(this.onFull.bind(this));
        levels.cookieJar.onBecomeVisible = this.createListener(this.makeControl.bind(this));
        levels.cookieJar.onFoundListener = this.createListener(this.runTooltip.bind(this));
    },

    onClick: function () {
        if (cleverapps.forces.isRunningForce(Forces.COOKIEJAR)) {
            cleverapps.forces.closeRunningForce();
        }

        if (levels.cookieJar.isFull()) {
            this.onFull();
        } else {
            cleverapps.focusManager.display({
                focus: "ExtraWindow",
                action: function (f) {
                    new ExtraWindow(this.extra);
                    cleverapps.focusManager.onceNoWindowsListener = f;
                }.bind(this)
            });
        }
    },

    updateSize: function () {
        var scale = cleverapps.styles.CookieJarView.scale;
        var isVertical = cleverapps.resolution.mode === cleverapps.WideMode.VERTICAL;

        this.baseScaleX = isVertical ? -scale : -1;
        this.baseScaleY = isVertical ? scale : 1;
        this.setScale(this.baseScaleX, this.baseScaleY);
    },

    makeControl: function () {
        cleverapps.focusManager.registerControl("cookieJar", new HidingNode(this));
    },

    addCookie: function () {
        var scene = cleverapps.scenes.getRunningScene();
        cleverapps.aims.showTarget(this);

        var position = cc.p(scene.width / 2, scene.height / 2);

        if (cleverapps.config.subtype === "stacks" && KeypadView.LAST_WORD_POSITION) {
            position = KeypadView.LAST_WORD_POSITION;
        }

        var word = cleverapps.scenes.getRunningScene().currentView.letters;
        if (word && word[0] instanceof cc.Node) {
            var afterAddCookie = cleverapps.wait(word.length, this.afterAddCookie.bind(this));
            word.reverse().forEach(function (letter, index) {
                var delay = index * 0.07;

                if (!letter || !letter.isRunning()) {
                    afterAddCookie();
                    return;
                }
                letter.replaceParentSamePlace(cleverapps.scenes.getMovingNode(this), {
                    keepScale: true
                });
                this.runCookieAnimation(letter, delay, afterAddCookie);
            }.bind(this));
        } else {
            var cookie = new cleverapps.Spine(bundles.cookiejar.jsons.cookie_json);
            cookie.setPositionRound(position);
            scene.addChild(cookie);
            cookie.setLocalZOrder(100);
            this.runCookieAnimation(cookie, 0, this.afterAddCookie.bind(this));
        }
    },

    afterAddCookie: function (word) {
        this.runTooltip(word);
        this.showForce();
    },

    runCookieAnimation: function (cookie, delay, afterCallback) {
        delay = delay || 0;
        afterCallback = afterCallback || this.afterAddCookie.bind(this);
        var styles = cleverapps.styles.CookieJarView;

        var target = cookie.getRelativeCoordinates(this.getParent());
        target.y += styles.prizeFlyPositionShift.y;
        target.x += styles.prizeFlyPositionShift.x;

        var first = cookie.getPosition();
        var third = target;
        var second = cc.p((first.x + 2 * third.x) / 3, (first.y + 2 * third.y) / 3 - styles.bezier.y);

        cookie.runAction(
            new cc.Spawn(
                new cc.Sequence(
                    new cc.DelayTime(0.1 + delay),
                    new cc.CallFunc(function () {
                        if (this.hasAnimation && this.hasAnimation("jump")) {
                            this.setAnimation(0, "jump", false);
                        }
                    }.bind(cookie)),
                    new cc.CallFunc(function () {
                        this.setAnimation(0, "collect", false);
                        this.addAnimation(0, "idle", true);
                    }.bind(this)),
                    new cc.BezierTo(0.8, [first, second, third]).easing(cc.easeIn(1)),
                    new cc.Hide(),
                    new cc.CallFunc(function () {
                        afterCallback();
                    }),
                    new cc.DelayTime(0.5),
                    new cc.CallFunc(function () {
                        levels.cookieJar.isRewardReceived();
                    }),
                    new cc.RemoveSelf()
                ),
                new cc.Sequence(
                    new cc.DelayTime(1.1 + delay),
                    new cc.CallFunc(function () {
                        cleverapps.audio.playSound(bundles.game.urls.jar_collect_effect, { throttle: 500 });
                    })
                )
            )
        );
    },

    runTooltip: function (word) {
        word = (word || Game.currentGame.current.lastWord.join("")).toUpperCase();
        var styles = cleverapps.styles.CookieJarView;
        var currentTooltip = cleverapps.tooltipManager.active;
        if (currentTooltip) {
            if (currentTooltip.text.string !== word) {
                currentTooltip.text.setString(word);
            }
        } else {
            cleverapps.tooltipManager.onClick(this, {
                text: word,
                size: connector.info.isMobile ? styles.mobileTooltipSize : styles.tooltipSize,
                forceShow: true,
                control: "cookieJarTooltip",
                duration: 2.0
            });
        }
    },

    onFull: function () {
        cleverapps.focusManager.display({
            focus: "ExtraWindow",
            action: function (f) {
                new ExtraWindow(Game.currentGame.extra);
                cleverapps.focusManager.onceNoWindowsListener = function () {
                    f();
                };
            }
        });
    },

    showForce: function () {
        if (cleverapps.forces.isAvailable(this, Forces.COOKIEJAR)) {
            cleverapps.focusManager.displayWhenFreeFocus({
                focus: "CookiejarForce",
                control: ["cookieJar", "cookieJarTooltip"],
                actions: [
                    function (f) {
                        cleverapps.notification.clear();
                        setTimeout(f, 250);
                    },
                    function (f) {
                        if (cleverapps.forces.isAvailable(this, Forces.COOKIEJAR)) {
                            cleverapps.forces.create(this, Forces.COOKIEJAR, {
                                importantNodes: cleverapps.tooltipManager.active ? [cleverapps.tooltipManager.active] : undefined,
                                highlights: cleverapps.tooltipManager.active ? [cleverapps.tooltipManager.active] : undefined
                            });
                            cleverapps.forces.onceForceClosed = f;
                        } else {
                            f();
                        }
                    }.bind(this)
                ]
            });
        }
    }
});

cleverapps.styles.CookieJarView = {
    scale: 1,

    positions: [
        {
            x: { align: "left", dx: 10 },
            y: { align: "bottom", dy: 15 }
        },

        {
            x: { align: "left", dx: 10 },
            y: { align: "bottom", dy: 15 }
        },

        {
            x: { align: "left", dx: 10 },
            y: { align: "bottom", dy: 15 }
        }
    ],

    prizeFlyPositionShift: {
        y: -10,
        x: 0
    },

    coins: [
        {
            radiusX: 85,
            radiusY: 95,
            count: 16,
            scale: 0.35
        },
        {
            radiusX: 55,
            radiusY: 65,
            count: 8,
            scale: 0.5,
            baseAngle: 20
        },
        {
            radiusX: 20,
            radiusY: 25,
            count: 3,
            scale: 0.55,
            baseAngle: 65
        }
    ],

    tooltipSize: {
        width: 200,
        height: 80
    },
    mobileTooltipSize: {
        width: 150,
        height: 60
    },

    coinsReward: {
        x: { align: "center" },
        y: { align: "center", dy: -20 }
    },

    bezier: {
        y: -200
    }
};