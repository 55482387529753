/**
 * Created by Denis Kuzin on 24 october 2024
 */

RewardTypes.metaProgress = {
    invisible: true,

    handler: function (value) {
        cleverapps.hose.changeProgress(value);
        return function () {};
    }
};