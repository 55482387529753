/**
 * Created by slava on 23.03.17.
 */

cleverapps.CrosswordGrid = cc.Node.extend({
    avoidNode: "BoardView",
    ctor: function (field) {
        this._super();
        this.setAnchorPoint(0.5, 0.5);
        this.layers = [];

        this.createGrid(field);
        cleverapps.UI.wrap(this);
    },

    createGrid: function (field) {
        var offset = cleverapps.styles.BoardView.Letter.offset;

        var layerNodes = [];
        [cleverapps.Board.PLACEHOLDER_LAYER, cleverapps.Board.LETTER_LAYER, cleverapps.Board.ANIMATION_LAYER].forEach(function (layer) {
            var layerNode = new cc.Node();
            layerNodes[layer] = layerNode;
            this.addChild(layerNode, layer);
        }.bind(this));

        this.letters = [];
        this.animations = [];
        this.placeholders = field.map(function (word, row) {
            return word.split("").map(function (letter, col) {
                if (letter !== cleverapps.Board.EMPTY) {
                    var placeholder = new cc.Sprite(bundles.game.frames.placeholder_png);
                    layerNodes[cleverapps.Board.PLACEHOLDER_LAYER].addChild(placeholder);

                    var x = col * (placeholder.width + offset) + placeholder.width / 2;
                    var y = (field[row].length - 1 - row) * (placeholder.height + offset) + placeholder.height / 2;

                    placeholder.setPosition(x, y);
                    placeholder.setCascadeColorEnabled(true);

                    if (cleverapps.config.debugMode) {
                        cleverapps.UI.onClick(placeholder, function () {
                            Game.currentGame.hintBooster.addHint({ row: row, column: col });
                        });
                    }

                    [cleverapps.Board.LETTER_LAYER, cleverapps.Board.ANIMATION_LAYER].forEach(function (layer) {
                        var layerPlaceholder = new cc.Node();
                        layerPlaceholder.setContentSize(placeholder.width, placeholder.height);
                        layerPlaceholder.setAnchorPoint(0.5, 0.5);
                        layerPlaceholder.setPosition(x, y);
                        layerPlaceholder.setCascadeColorEnabled(true);

                        var container = layer === cleverapps.Board.LETTER_LAYER ? this.letters : this.animations;
                        if (!container[row]) {
                            container[row] = [];
                        }
                        container[row][col] = layerPlaceholder;
                        layerNodes[layer].addChild(layerPlaceholder);
                    }.bind(this));

                    return placeholder;
                }
                if (!this.letters[row]) {
                    this.letters[row] = [];
                }
                this.letters[row][col] = undefined;
                if (!this.animations[row]) {
                    this.animations[row] = [];
                }
                this.animations[row][col] = undefined;
            }.bind(this));
        }.bind(this));

        layerNodes.forEach(function (node) {
            cleverapps.UI.wrap(node);
        });

        this.layers = [this.placeholders, this.letters, this.animations];
    },

    getCell: function (row, col, layer) {
        layer = layer || cleverapps.Board.PLACEHOLDER_LAYER;

        if (!this.layers || !this.layers[layer] || !this.layers[layer][row]) {
            var layersData = "Layers length: " + this.layers.length + "\n";
            this.layers.forEach(function (layer, index) {
                layersData += index + ": " + layer.length + " ";
            });
            throw "No cell found for " + layer + " " + row + " " + col + "\n" + layersData;
        }

        return this.layers[layer][row][col];
    },

    putInCell: function (row, col, item, zOrder, layer) {
        layer = layer || cleverapps.Board.PLACEHOLDER_LAYER;

        var cell = this.layers[layer][row][col];
        cell.addChild(item, zOrder);
        item.setPosition(cell.width / 2, cell.height / 2);
    },

    isExistsCell: function (row, col) {
        return this.placeholders[row] && this.placeholders[row][col];
    },

    listCells: function (layer) {
        var activePlaceholders = [];
        for (var i = 0; i < this.placeholders.length; i++) {
            for (var j = 0; j < this.placeholders[i].length; j++) {
                if (this.placeholders[i][j]) {
                    switch (layer) {
                        case cleverapps.Board.PLACEHOLDER_LAYER:
                            activePlaceholders.push(this.placeholders[i][j]);
                            break;
                        case cleverapps.Board.LETTER_LAYER:
                            activePlaceholders.push(this.letters[i][j]);
                            break;
                        case cleverapps.Board.ANIMATION_LAYER:
                            activePlaceholders.push(this.animations[i][j]);
                            break;
                        default:
                            activePlaceholders.push(this.placeholders[i][j]);
                            activePlaceholders.push(this.letters[i][j]);
                            activePlaceholders.push(this.animations[i][j]);
                            break;
                    }
                }
            }
        }

        return activePlaceholders;
    },

    getColumn: function (index, layer) {
        layer = layer || cleverapps.Board.PLACEHOLDER_LAYER;

        return this.layers[layer][index];
    }
});