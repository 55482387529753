/**
 * Created by andrey on 15.10.2020.
 */

cleverapps.BoardView.prototype.adminCustomizeSuper = cleverapps.BoardView.prototype.customize;

cleverapps.BoardView.prototype.customize = function () {
    this.adminCustomizeSuper();

    if (!this.board.level.quick) {
        return;
    }

    this.board.words.forEach(function (word) {
        this.board.listWordIndexes(word).forEach(function (pos) {
            var char = this.board.field[pos[0]][pos[1]];
            this.animateOpenLetter(pos[0], pos[1], char, true);
        }.bind(this));
    }.bind(this));

    this.setContentSize2(this.grid.getContentSize());
    [this.grid, this.boardBg].forEach(function (child) {
        if (child) {
            child.setPositionRound(this.width / 2, this.height / 2);
            child.setScaleX(this.width / child.width);
            child.setScaleY(this.height / child.height);
        }
    }.bind(this));
};