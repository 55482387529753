/**
 * Created by Ivan on 17.10.2024
 */

var OpenLetterButtonBooster = BaseButtonBooster.extend({
    ctor: function (booster) {
        this._super({
            booster: booster,
            styles: cleverapps.styles.OpenLetterButtonBooster
        });
    },

    createIcon: function () {
        return new cc.Sprite(bundles.buttons.frames.open_letter_png);
    }
});

cleverapps.styles.OpenLetterButtonBooster = {
    width: 150,
    height: 120,

    position: [
        { x: { align: "right", dx: -20 }, y: { align: "bottom", dy: 620 } },
        { x: { align: "right", dx: -20 }, y: { align: "bottom", dy: 620 } },
        { x: { align: "right", dx: -20 }, y: { align: "bottom", dy: 650 } }
    ],
    phone: {
        scale: 1.0
    },
    content: {
        margin: -20,
        direction: cleverapps.UI.VERTICAL,
        layout: {
            x: { align: "center", dx: 0 },
            y: { align: "center", dy: 20 }
        }
    }
};