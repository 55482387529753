/**
 * Created by slava on 24.03.17.
 */

cleverapps.Board = function (game, foundWords, hints) {
    cleverapps.EventEmitter.call(this);

    this.game = game;
    this.level = game.level;
    this.levelContent = game.level.content;
    this.words = cleverapps.unique(this.levelContent.words);

    if (this.words.length !== this.levelContent.words.length && cleverapps.config.debugMode) {
        throw "Duplicate board words";
    }

    this.setCrosswordGrid();

    this.wordsSet = cleverapps.createSet(this.words);
    this.allFoundWords = foundWords;
    foundWords = foundWords.filter(this.contains, this);
    this.foundWordsSet = cleverapps.createSet(foundWords);

    this.hints = hints || [];

    this.getView = function () {};
};

cleverapps.Board.prototype = Object.create(cleverapps.EventEmitter.prototype);
cleverapps.Board.prototype.constructor = cleverapps.Board;

cleverapps.Board.prototype.setCrosswordGrid = function () {
    if (this.levelContent.field) {
        this.field = this.levelContent.field;
        this.gridType = "crossword";
        this.crosswordFindWordIndexes = cleverapps.crosswordFindWordIndexes.bind(this);
        this.updateGuessedField();
        return;
    }
    this.setWordPerlineGrid();
};

cleverapps.Board.prototype.setWordPerlineGrid = function () {
    this.field = this.words.map(function (word) {
        return word;
    });
    this.gridType = "perline";
    this.crosswordFindWordIndexes = undefined;
    this.updateGuessedField();
};

cleverapps.Board.prototype.updateGuessedField = function () {
    this.guessedField = this.field.map(function (line) {
        var str = "";
        for (var i = 0; i < line.length; i++) {
            if ([cleverapps.Board.EMPTY, cleverapps.Board.GOLD, cleverapps.Board.STAR, cleverapps.Board.GUESSED].indexOf(line.charAt(i)) !== -1) {
                str += line.charAt(i);
            } else {
                str += cleverapps.Board.NOT_GUESSED;
            }
        }
        return str;
    });
};

cleverapps.Board.prototype.showUp = function (f, silent) {
    var eyeBooster = cleverapps.boosters.getBoosterById(cleverapps.Boosters.TYPE_EYE);

    if (this.game.actionsBefore.some(function (action) {
        return action.source === eyeBooster;
    })) {
        this.trigger("showUp", f);
        return;
    }

    this.trigger("showUp", function () {
        this.showUpLetters(silent);
        this.showUpCoins(silent);
        this.showUpStars(silent);
        this.trigger("afterShowUp", silent);
        f();
    }.bind(this), silent);
};

cleverapps.Board.prototype.showUpLetters = function (silent) {
    Object.keys(this.foundWordsSet).forEach(function (word) {
        if (this.listWordIndexes(word)) {
            this.listWordIndexes(word).forEach(function (index) {
                this.openLetter(index[0], index[1], { silent: true });
            }, this);

            this.trigger("afterDiscover", word, silent || cleverapps.config.subtype !== "stacks");
        }
    }, this);

    this.hints.concat(this.levelContent.opened || []).forEach(function (hint) {
        this.openLetter(hint[0], hint[1], { silent: true });
    }, this);

    if (!silent) {
        this.trigger("showUpLetters");
    }
};

cleverapps.Board.prototype.showUpCoins = function (silent) {
    var wantCoins = this.levelContent.gold || [];
    if (this.level.quick || this.level.isRegular() && !this.level.isCurrentLevel() || cleverapps.config.subtype === "stacks") {
        wantCoins = [];
    }
    if ((this.level.isBonusRound() || this.level.isBonus()) && cleverapps.config.subtype !== "stacks") {
        wantCoins = this.listEmptyPositions().map(function (position) {
            return [position.row, position.column];
        });
    }
    wantCoins.forEach(function (position) {
        if (this.isEmptyLetterUnguessed(position)) {
            this.addCoin(position[0], position[1]);
        }
    }, this);

    if (!silent) {
        this.trigger("showUpCoins");
    }
};

cleverapps.Board.prototype.showUpStars = function (silent) {
    if (Game.currentGame.secondaryMission && Game.currentGame.secondaryMission.type === Mission.TYPE_LETTER) {
        this.addMissionStars();
    }

    if (!silent) {
        this.trigger("showUpStars");
    }
};

cleverapps.Board.prototype.calcHints = function () {
    var getKey = function (item) {
        return item[0] + "_" + item[1];
    };

    var discoveredIndexes = {};
    Object.keys(this.foundWordsSet).forEach(function (word) {
        this.listWordIndexes(word).forEach(function (index) {
            discoveredIndexes[getKey(index)] = true;
        });
    }.bind(this));

    var hints = [];

    this.hints.forEach(function (hint) {
        if (!discoveredIndexes[getKey(hint)]) {
            hints.push(hint);
        }
    });

    for (var i = 0; i < this.field.length; i++) {
        for (var j = 0; j < this.field[i].length; j++) {
            if (this.isLetterGuessed(i, j) && !discoveredIndexes[getKey([i, j])]) {
                hints.push([i, j]);
            }
        }
    }

    return cleverapps.unique(hints, getKey);
};

cleverapps.Board.prototype.hide = function (f) {
    this.trigger("hide", f);
};

cleverapps.Board.prototype.countTotalWords = function () {
    return this.words.length;
};

cleverapps.Board.prototype.countDiscoveredWords = function () {
    return Object.keys(this.foundWordsSet).length;
};

cleverapps.Board.prototype.listWordIndexes = function (word) {
    if (this.wordIndexes === undefined) {
        this.wordIndexes = {};
    }

    if (this.wordIndexes[word] === undefined) {
        this.wordIndexes[word] = this._findWordIndexes(word);
    }

    return this.wordIndexes[word];
};

cleverapps.Board.prototype.addMissionStars = function () {
    this.words.forEach(function (word, id) {
        var seed = this.level.getHumanReadableNumber() + id * word.length;
        var empty = this.listWordIndexes(word).filter(this.isEmptyLetterUnguessed, this);

        if (empty.length === 0) {
            return;
        }

        var maxAmount = Math.max(Math.round(empty.length / 3), 1);
        var amount = cleverapps.Random.random(0, maxAmount, seed);
        while (amount > 0) {
            var chosenIndex = cleverapps.Random.random(0, empty.length - 1, seed + amount + 1);
            var chosen = empty[chosenIndex];

            this.addStar(chosen[0], chosen[1]);

            empty.splice(chosenIndex, 1);
            amount--;
        }
    }, this);
};

cleverapps.Board.prototype.addStar = function (row, col) {
    if (this.isEmptyLetterUnguessed(row, col)) {
        var type = cleverapps.Random.random(0, Missions[Mission.TYPE_LETTER].STAR_TYPES_AMOUNT - 1);
        this._setCharAt(row, col, cleverapps.Board.STAR);

        this.trigger("addStar", row, col, type);
    }
};

cleverapps.Board.prototype.addState = function (row, col, state) {
    if (this.isEmptyLetterUnguessed(row, col)) {
        this._setCharAt(row, col, state);
    }
};

cleverapps.Board.prototype.collectStar = function (row, col) {
    this.game.addClover(Mission.TYPE_LETTER, 1, true);
    this.game.counter.setTimeout(function () {
        this.game.trigger("rewardClover");
    }.bind(this), cleverapps.Board.FLIGHT_DURATION);

    this.trigger("collectStar", row, col);
};

cleverapps.Board.prototype.addCoin = function (row, col) {
    if (this.isEmptyLetterUnguessed(row, col)) {
        this._setCharAt(row, col, cleverapps.Board.GOLD);

        this.trigger("addCoin", row, col);
    }
};

cleverapps.Board.prototype.collectCoin = function (row, col) {
    this.game.collectedCoins += WordGame.BOARD_COIN;
    this.trigger("collectCoin", row, col);
};

cleverapps.Board.prototype._setCharAt = function (row, col, value) {
    var p = this.guessedField[row];
    this.guessedField[row] = p.substr(0, col) + value + p.substr(col + 1);
};

cleverapps.Board.prototype.openLetter = function (row, col, options, callback) {
    if (typeof row !== "number") {
        col = row[1];
        row = row[0];
    }

    options = options || {};
    callback = callback || function () {};

    options.type = options.type || cleverapps.Board.DISCOVER_LETTER_ANIMATION;

    if (!this.isLetterUnguessed(row, col)) {
        if (options.keypadLetter && cleverapps.config.subtype === "stacks") {
            this.trigger("openLetter", row, col, options, callback);
        } else {
            callback();
        }

        return false;
    }

    if (this.guessedField[row][col] === cleverapps.Board.GOLD) {
        this.collectCoin(row, col);
    } else if (this.guessedField[row][col] === cleverapps.Board.STAR) {
        this.collectStar(row, col);
    }

    this.trigger("collect", row, col);

    this._setCharAt(row, col, cleverapps.Board.GUESSED);

    this.trigger("openLetter", row, col, options, callback);

    return true;
};

cleverapps.Board.prototype.isLetterGuessed = function (row, col) {
    if (typeof row !== "number") {
        col = row[1];
        row = row[0];
    }

    return this.guessedField[row] && this.guessedField[row].charAt(col) === cleverapps.Board.GUESSED;
};

cleverapps.Board.prototype.isEmptyLetterUnguessed = function (row, col) {
    if (typeof row !== "number") {
        col = row[1];
        row = row[0];
    }

    return this.guessedField[row] && this.guessedField[row].charAt(col) === cleverapps.Board.NOT_GUESSED;
};

cleverapps.Board.prototype.isLetterUnguessed = function (row, col) {
    if (typeof row !== "number") {
        col = row[1];
        row = row[0];
    }

    if (this.guessedField[row] && cleverapps.Board.unguessedLetters.indexOf(this.guessedField[row].charAt(col)) !== -1) {
        return this.guessedField[row].charAt(col);
    }

    return undefined;
};

cleverapps.Board.prototype.findNewlyOpenedWord = function () {
    for (var i = 0; i < this.words.length; i++) {
        var word = this.words[i];

        if (this.foundWordsSet[word] === undefined) {
            if (this.listWordIndexes(word).filter(this.isLetterGuessed, this).length === word.length) {
                return word;
            }
        }
    }
};

cleverapps.Board.prototype.addHint = function (row, col, type) {
    if (this.openLetter(row, col, { type: type || cleverapps.Board.HINT_LETTER_ANIMATION })) {
        cleverapps.userStatus.reportUserAction();
    }
};

cleverapps.Board.prototype.listEmptyPositions = function () {
    var positions = [];

    for (var i = 0; i < this.field.length; i++) {
        for (var j = 0; j < this.field[i].length; j++) {
            if (this.isLetterUnguessed(i, j)) {
                positions.push({ row: i, column: j });
            }
        }
    }

    return positions;
};

cleverapps.Board.prototype.listEmptyUnguessedPositions = function () {
    var positions = [];

    for (var i = 0; i < this.field.length; i++) {
        for (var j = 0; j < this.field[i].length; j++) {
            if (this.isEmptyLetterUnguessed(i, j)) {
                positions.push({ row: i, column: j });
            }
        }
    }

    return positions;
};

cleverapps.Board.prototype.selectHintPosition = function () {
    var positions = this.listEmptyPositions();

    if (positions.length === 0) {
        return undefined;
    }

    if (cleverapps.Board.hintType === cleverapps.Board.HINT_TYPES.RANDOM) {
        return cleverapps.Random.choose(positions);
    }

    return positions[0];
};

cleverapps.Board.prototype.selectLettersPositions = function (letter) {
    var positionsForOpen = [];
    for (var word in this.wordIndexes) {
        this.wordIndexes[word].forEach(function (letterIndex, index) {
            if (word[index] === letter && this.isLetterUnguessed(letterIndex[0], letterIndex[1])) {
                var alreadyExists = positionsForOpen.some(function (existingIndex) {
                    return existingIndex[0] === letterIndex[0] && existingIndex[1] === letterIndex[1];
                });

                if (!alreadyExists) {
                    positionsForOpen.push(letterIndex);
                }
            }
        }.bind(this));
    }

    return positionsForOpen.filter(function (value, index, arr) {
        return arr.indexOf(value) === index;
    });
};

cleverapps.Board.prototype.isComplete = function () {
    return cleverapps.contains(Object.keys(this.foundWordsSet), Object.keys(this.wordsSet));
};

cleverapps.Board.prototype.getAllFoundWords = function () {
    return cleverapps.unique(this.allFoundWords.concat(Object.keys(this.foundWordsSet)));
};

cleverapps.Board.prototype.alreadyDiscovered = function (word) {
    this.trigger("alreadyDiscovered", this.listWordIndexes(word));
};

cleverapps.Board.prototype.discover = function (word, keypadLetters, callback) {
    var indexes = this.listWordIndexes(word);
    this.foundWordsSet[word] = true;
    this.checkOneWordLeft();

    this.trigger("beforeDiscover", word);

    var counter = cleverapps.wait(indexes.length, function () {
        Game.currentGame.counter.dec();
    });

    Game.currentGame.counter.inc();
    indexes.forEach(function (index, i) {
        this.openLetter(index[0], index[1], {
            keypadLetter: keypadLetters[i]
        }, counter);
    }, this);

    Game.currentGame.counter.onceZero = this.trigger.bind(this, "afterDiscover", word, this.isComplete(), callback);
};

cleverapps.Board.prototype.checkOneWordLeft = function () {
    if (this.isComplete() || this.lastWordFound) {
        return;
    }

    var words = this.getUndiscoveredWords();
    if (words.length === 1) {
        this.lastWordFound = true;
        this.trigger("lastWord", this.listWordIndexes(words[0]));
    }
};

cleverapps.Board.prototype.getUndiscoveredWords = function () {
    return this.words.filter(function (word) {
        return !this.foundWordsSet[word];
    }, this);
};

cleverapps.Board.prototype.contains = function (word) {
    return this.wordsSet[word] !== undefined;
};

cleverapps.Board.prototype.analyze = function (word) {
    if (this.foundWordsSet[word] !== undefined) {
        return WordGame.STATUSES.BOARD_FOUND;
    }

    return WordGame.STATUSES.BOARD;
};

cleverapps.Board.prototype._findWordIndexes = function (word) {
    if (this.crosswordFindWordIndexes) {
        return this.crosswordFindWordIndexes(word);
    }

    for (var row = 0; row < this.words.length; row++) {
        if (this.words[row] === word) {
            return word.split("").map(function (char, col) {
                return [row, col];
            });
        }
    }
    return [];
};

cleverapps.Board.prototype.getWordByIndexes = function (row) {
    return this.words[row];
};

cleverapps.Board.prototype.setColumnsAmount = function (columnsCount) {
    this.trigger("setColumnsAmount", columnsCount);
};

cleverapps.Board.prototype.highlightWord = function (word) {
    var indexes = this.listWordIndexes(word);

    indexes.forEach(function (index) {
        this.trigger("highlightLetter", index[0], index[1]);
    }, this);
};

cleverapps.Board.prototype.clearHighlight = function () {
    this.trigger("clearHighlight");
};

cleverapps.Board.EMPTY = ".";
cleverapps.Board.NOT_GUESSED = "_";
cleverapps.Board.GOLD = "@";
cleverapps.Board.STAR = "*";
cleverapps.Board.GUESSED = "1";

cleverapps.Board.unguessedLetters = [cleverapps.Board.NOT_GUESSED, cleverapps.Board.GOLD, cleverapps.Board.STAR];

cleverapps.Board.DISCOVER_LETTER_ANIMATION = 0;
cleverapps.Board.HINT_LETTER_ANIMATION = 1;
cleverapps.Board.SPAWN_LETTER_ANIMATION = 2;

cleverapps.Board.HINT_TYPES = {
    FIRST: 0,
    RANDOM: 1
};

cleverapps.Board.hintType = cleverapps.Board.HINT_TYPES.FIRST;

cleverapps.Board.PLACEHOLDER_LAYER = 0;
cleverapps.Board.LETTER_LAYER = 1;
cleverapps.Board.ANIMATION_LAYER = 2;

cleverapps.Board.FLIGHT_DURATION = 700;
cleverapps.Board.MAX_SCALE = 1;