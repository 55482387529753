/**
 * Created by Andrey Popov on 07.06.2021
 */

var CryptexMenuBarItemModel = function (config) {
    MenuBarItem.call(this, config);
};

CryptexMenuBarItemModel.prototype = Object.create(MenuBarItem.prototype);
CryptexMenuBarItemModel.prototype.constructor = CryptexMenuBarItemModel;

CryptexMenuBarItemModel.prototype.customInit = function () {
    Game.currentGame.cryptex.onShowForceListener = function () {
        this.onShowForce(this.config.force);
    }.bind(this);

    Game.currentGame.cryptex.onStop = function () {
        this.onRemove();
    }.bind(this);

    var timeLeft = Game.currentGame.cryptex.countdown.getTimeLeft();
    if (timeLeft > 0) {
        this.setTimer(new cleverapps.CountDown(timeLeft, {
            onFinish: this.updateTimer.bind(this)
        }));
    }
};