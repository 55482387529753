/**
 * Created by Vladislav on 25.09.2024.
 */

Placements.ENTRIES["keypadForce"] = {
    priority: -1001,
    type: Placements.FREE_FOCUS_GAME,

    filter: function () {
        return Game.currentGame && Game.currentGame.keypad && Game.currentGame.keypad.getAvailableForce();
    },

    action: function () {
        var force = Game.currentGame.keypad.getAvailableForce();
        if (force) {
            cleverapps.focusManager.display({
                focus: "Keypad.force",
                action: function (f) {
                    Game.currentGame.keypad.showForce(f, force);
                }
            });
        }
    }
};