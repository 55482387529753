/**
 * Created by mac on 11/27/20
 */

var regularShowUp = function (elements, delay) {
    delay = delay || 0;
    Object.values(elements).forEach(function (element) {
        var scale = element.getScale();
        element.setScale(scale * 0.7);
        element.setOpacity(0);
        element.setCascadeOpacityEnabledRecursively(true);

        element.runAction(new cc.Sequence(
            new cc.DelayTime(delay),
            new cc.Spawn(
                new cc.FadeIn(0.4),
                new cc.ScaleTo(0.4, scale)
            )
        ));
    });
};

var coinsShowUp = function (elements) {
    var styles = cleverapps.styles.BoardView.Coin;

    Object.values(elements).forEach(function (element) {
        var scale = element.getScale();
        element.setVisible(false);

        element.runAction(new cc.Sequence(
            new cc.MoveBy(0, 0, styles.showUpOffset),
            new cc.ScaleTo(0, scale * 0.7),
            new cc.DelayTime(element.order * 0.05),
            new cc.PlaySound(bundles.game.urls.show_up_coins, { throttle: 0 }),
            new cc.Spawn(
                new cc.CallFunc(function () {
                    element.setVisible(true);
                    element.setTimeScale(1);
                    element.setAnimationAndIdleAfter("fadein", "shine");
                    element.setCompleteListenerOnce(function () {
                        element.setTimeScale(0.5 * Math.random());
                    });
                }),
                new cc.MoveBy(0.4, 0, -styles.showUpOffset).easing(cc.easeIn(1)),
                new cc.ScaleTo(0.4, scale)
            )
        ));
    });
};

cleverapps.BoardView.prototype.showUpAnimation = function (f, silent) {
    this.setVisible(true);
    this.showUpBackground(silent);

    if (!silent) {
        cleverapps.audio.playSound(bundles.game.urls.show_up);
        regularShowUp(this.grid.listCells());
    }

    f();
};

cleverapps.BoardView.prototype.showUpBackground = function (silent) {
    if (!this.boardBg) {
        return;
    }
    if (silent) {
        this.boardBg.setOpacity(255);
        return;
    }

    this.boardBg.runAction(new cc.FadeIn(0.4));
};

cleverapps.BoardView.prototype.showUpLettersAnimation = function () {
    regularShowUp(this.letters);
};

cleverapps.BoardView.prototype.showUpCoinsAnimation = function () {
    coinsShowUp(this.coins);
};

cleverapps.BoardView.prototype.showUpStarsAnimation = function () {
    regularShowUp(this.stars);
};