/**
 * Created by decipaliz on 15.03.2023
 */

ComingSoonLocationView.prototype.customize = cleverapps.extendFunc(ComingSoonLocationView.prototype.customize, function () {
    this._super.apply(this, arguments);

    var styles = cleverapps.styles.LocationView.comingSoon.character;
    if (!styles) {
        return;
    }

    // TODO: fixsize
    var person = new cleverapps.Spine(bundles.coming_soon.jsons.coming_soon_hero_json);
    person.setAnimation(0, "coming_soon", true);
    person.setPosition(styles.x, styles.y);
    this.addChild(person);
});

ComingSoonLocationView.prototype.addBackground = function () {
    this.addDefaultBackground();
};