/**
 * Created by vladislav on 24.11.2020
 */

var KeypadLetter = function (symbol, index, keypad) {
    cleverapps.EventEmitter.call(this);

    this.symbol = symbol;
    this.index = index;
    this.keypad = keypad;
};

KeypadLetter.prototype = Object.create(cleverapps.EventEmitter.prototype);
KeypadLetter.prototype.constructor = KeypadLetter;

KeypadLetter.prototype.select = function () {
    this.selected = true;

    this.trigger("select");
};

KeypadLetter.prototype.unselect = function () {
    this.selected = false;

    this.trigger("unselect");
};

KeypadLetter.prototype.status = function (code) {
    this.selected = false;

    this.trigger("status", code);
};

KeypadLetter.prototype.shuffle = function (index) {
    this.index = index;
};