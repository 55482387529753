/**
 * Created by vladislav on 12.07.2021
 */

var TreasureSearchMissionLogic = function (mission) {
    this.mission = mission;
    this.minigame = mission.minigame;

    this.checkComplete();

    this.onUpdateCurrency = function () {};
};

TreasureSearchMissionLogic.prototype.checkComplete = function () {
    if (this.isFinished()) {
        this.mission.complete();
    }
};

TreasureSearchMissionLogic.prototype.isFinished = function () {
    return this.mission.result > TreasureSearch.FIELD_SIZE_BY_STAGES.length - 1;
};

TreasureSearchMissionLogic.prototype.processEvent = function (options) {
    this.mission.updateCurrency(options.amount, options.details);

    if (options.amount > 0) {
        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.TREASURE_SEARCH_RECEIVE_SHOVELS, {
            value: options.amount
        });
    }

    this.onUpdateCurrency();
};

TreasureSearchMissionLogic.prototype.getLevelReward = function () {
    return Game.currentGame.getNormalizedLevelReward(RewardsConfig.TreasureSearchLevelRewards, "match3");
};

TreasureSearchMissionLogic.prototype.getResult = function () {
    return this.mission.result;
};
