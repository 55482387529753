/**
 * Created by slava on 23.03.17.
 */

var HoseScene = MainScene.extend({
    ctor: function (options) {
        this._super(options);

        var currentLevel = cleverapps.hose.getCurrentPage();
        if (cleverapps.hose.changeEpisodeAnimation) {
            currentLevel -= 1;
        }

        this.pagingScroll = new PagingScroll(cleverapps.hose.getTotalPages());
        this.pagingScroll.scrollToLevel(0, currentLevel);
    },

    onSceneLoaded: function () {
        this._super();

        if (Game.currentGame) {
            cleverapps.throwAsync("Game.currentGame not cleared");
        }

        cleverapps.hose.onReset = this.createListener(this.reset.bind(this));
        cleverapps.hose.onGotoNextLocation = this.createListener(this.gotoNextLocation.bind(this));
        cleverapps.hose.onAnimateOpen = this.createListener(this.animateOpen.bind(this));
        cleverapps.hose.onAnimateClose = this.createListener(this.animateClose.bind(this));

        var episodesView = new PagingScrollView(this.pagingScroll);
        episodesView.setLocalZOrder(-1);
        this.addChild(episodesView);

        if (typeof PlayButtonView !== "undefined") {
            var playButton = new PlayButtonView();
            playButton.addFinger();
            this.addChild(playButton);

            cleverapps.focusManager.registerControl("play_button", new HidingNode(playButton, cleverapps.UI.VERTICAL));
        }
    },

    getBackgroundStyles: function () {
        var bundleName = cleverapps.hose.getBackgroundBundle();
        if (bundleName) {
            return {
                bundle: bundleName,
                backgroundId: "background"
            };
        }
    },

    listBundles: function () {
        var res = [];

        var nonBlockingUrls = [];

        this.pagingScroll.listVisiblePages().forEach(function (page) {
            var externalBundleId = page.getResourcesBundleId();
            if (bundles[externalBundleId]) {
                if (connector.info.isNative) {
                    nonBlockingUrls = nonBlockingUrls.concat(Object.values(bundles[externalBundleId].urls));
                } else {
                    res.push(externalBundleId);
                }
            }

            if (page.isComingSoon()) {
                res.push("episode_coming_soon");
            }
        });

        nonBlockingUrls.forEach(function (url) {
            cc.loader.load(url);
        });

        var backgroundBundle = cleverapps.hose.getBackgroundBundle();
        if (backgroundBundle) {
            res.push(backgroundBundle);
        }

        if (cleverapps.config.rpg) {
            res = res.concat(["episodes_resources"]);
        }

        return res;
    },

    reset: function () {
        this.pagingScroll.reset();
    },

    gotoNextLocation: function (callback, toEpisode) {
        this.pagingScroll.gotoNextPage(callback, toEpisode);
    },

    animateOpen: function (f, page) {
        this.pagingScroll.animateOpen(f, page);
    },

    animateClose: function (f, page) {
        this.pagingScroll.animateClose(f, page);
    }
});

cleverapps.styles.HoseScene = {};
