/**
 * Created by vladislav on 13.12.2019
 */

var ExtraWindow = CleverappsWindow.extend({
    onWindowLoaded: function (extra) {
        this.extra = extra;

        this._super({
            name: "extrawindow",
            title: "ExtraWindow.title",
            content: this.getContent(),
            styles: cleverapps.styles.ExtraWindow.window
        });
    },

    createTopBg: function () {
        return new cc.Sprite(bundles.extra_window.frames.extra_words_banner_png);
    },

    createNoExtraWordsText: function () {
        var styles = cleverapps.styles.ExtraWindow;

        var textNode = new cc.Node();
        textNode.setAnchorPoint(0.5, 0.5);
        textNode.setContentSize2(styles.noWordsText);

        var text = cleverapps.UI.generateOnlyText("ExtraWindow.noWordsText", cleverapps.styles.FONTS.WINDOW_TEXT);
        textNode.addChild(text);
        text.setDimensions(styles.noWordsText.width, 0);
        text.fitTo(undefined, styles.noWordsText.height);
        text.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);
        text.setPositionRound(textNode.width / 2, textNode.height / 2);

        return textNode;
    },

    createFoundExtraWordsText: function () {
        var foundWordsText = cleverapps.UI.generateOnlyText("ExtraWindow.foundWord", cleverapps.styles.FONTS.EXTRA_WINDOW_TEXT);
        foundWordsText.setDimensions(cleverapps.styles.ExtraWindow.infoTextWidth, 0);
        foundWordsText.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);

        return foundWordsText;
    },

    createWordList: function (words) {
        var styles = cleverapps.styles.ExtraWindow;

        return new WordsListComponent({
            words: words,
            width: styles.list.width,
            height: styles.list.height,
            minimalWordsAmount: 8
        });
    },

    getContent: function () {
        var styles = cleverapps.styles.ExtraWindow;

        var words = this.extra.foundList.slice().sort(function (word1, word2) {
            return word2.length - word1.length;
        });

        var items = [];

        items.push(this.createTopBg());

        if (words.length === 0) {
            items.push(this.createNoExtraWordsText());
        } else {
            items.push(new cleverapps.Layout([this.createFoundExtraWordsText(), this.createWordList(words)], {
                direction: cleverapps.UI.VERTICAL,
                margin: styles.infoTextMargin
            }));
        }

        items.push(this.addCookieJarInfo());

        return new cleverapps.Layout(items, {
            direction: cleverapps.UI.VERTICAL,
            margin: styles.margin
        });
    },

    addCookieJarInfo: function () {
        var styles = cleverapps.styles.ExtraWindow;

        var text = cleverapps.UI.generateOnlyText("ExtraWindow.info", cleverapps.styles.FONTS.WINDOW_SMALL_TEXT, {
            cookiesToReward: cleverapps.CookieJar.COOKIES_TO_REWARD_GOAL
        });
        text.setDimensions(styles.infoTextWidth, 0);
        text.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);
        text.setVerticalAlignment(cc.TEXT_ALIGNMENT_CENTER);

        var currentValue = levels.cookieJar.getCounterValue();
        var goalValue = cleverapps.CookieJar.COOKIES_TO_REWARD_GOAL;

        this.prize = new PrizeBarComponent({
            reward: RewardsConfig.CookieJar,
            onCollect: this.createListener(this.close.bind(this))
        });
        this.prize.updateProgress(currentValue, goalValue);

        return new cleverapps.Layout([text, this.prize], {
            direction: cleverapps.UI.VERTICAL,
            margin: styles.progressBar.margin,
            reversed: true
        });
    },

    onClose: function () {
        if (levels.cookieJar.isFull()) {
            levels.cookieJar.reset();
            new RewardWindow(RewardsConfig.CookieJar, { event: cleverapps.EVENTS.EARN.COOKIE_JAR });
        }
    },

    listBundles: function () {
        return ["extra_window"];
    }
});

cleverapps.styles.ExtraWindow = {
    margin: 20,
    list: {
        height: 306,
        width: 300
    },
    tooltip: {
        margin: 5,
        iconOffsetY: 2
    },
    progressBar: {
        length: 400,
        margin: 60
    },
    prize: {
        x: { align: "right", dx: 50 },
        y: { align: "center", dy: 0 },
        width: 80,
        height: 80,
        scale: 0.5
    },
    progressText: {
        y: 40
    },
    infoTextWidth: 800,
    infoTextMargin: 20,

    noWordsText: {
        x: { align: "center" },
        y: { align: "center" },
        width: 450,
        height: 200
    },

    window: {
        padding: {
            top: -31,
            left: 25,
            right: 25,
            bottom: 80
        },
        CloseButton: {
            x: { align: "right", dx: -40 },
            y: { align: "top", dy: -35 }
        },
        HelpButton: {
            x: { align: "left", dx: 53 },
            y: { align: "top", dy: -50 }
        }
    }
};