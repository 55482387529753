/**
 * Created by Vladislav on 25.09.2024.
 */

Placements.ENTRIES["cryptexForce"] = {
    type: Placements.FREE_FOCUS_GAME,
    priority: -1001,

    filter: function () {
        return Game.currentGame && Game.currentGame.cryptex && Game.currentGame.cryptex.canShowForce();
    },

    action: function () {
        Game.currentGame.cryptex.showForce();
    }
};