/**
 * Created by slava on 23.03.17.
 */

cleverapps.WordPerlineGrid = cc.Node.extend({
    avoidNode: "BoardView",
    ctor: function (field) {
        this._super();
        this.setAnchorPoint(0.5, 0.5);
        this.createGrid(field);
        this.placeholderSize = new cc.Sprite(bundles.game.frames.placeholder_png).getContentSize();
    },

    createGrid: function (field) {
        var layerNodes = [];
        [cleverapps.Board.PLACEHOLDER_LAYER, cleverapps.Board.LETTER_LAYER, cleverapps.Board.ANIMATION_LAYER].forEach(function (layer) {
            var layerNode = new cc.Node();
            layerNodes[layer] = layerNode;
            this.addChild(layerNode, layer);
        }.bind(this));

        this.letters = [];
        this.animations = [];
        this.placeholders = field.map(function (word, row) {
            return word.split("").map(function (letter, col) {
                var placeholder = new cc.Sprite(bundles.game.frames.placeholder_png);
                layerNodes[cleverapps.Board.PLACEHOLDER_LAYER].addChild(placeholder);

                if (cleverapps.config.debugMode) {
                    cleverapps.UI.onClick(placeholder, function () {
                        Game.currentGame.hintBooster.addHint({ row: row, column: col });
                    });
                }

                [cleverapps.Board.LETTER_LAYER, cleverapps.Board.ANIMATION_LAYER].forEach(function (layer) {
                    var layerPlaceholder = new cc.Node();
                    layerPlaceholder.setContentSize(placeholder.width, placeholder.height);
                    layerPlaceholder.layer = "layer_" + layer;
                    layerPlaceholder.setAnchorPoint(0.5, 0.5);

                    var container = layer === cleverapps.Board.LETTER_LAYER ? this.letters : this.animations;
                    if (!container[row]) {
                        container[row] = [];
                    }
                    container[row][col] = layerPlaceholder;
                    layerNodes[layer].addChild(layerPlaceholder);
                }.bind(this));

                return placeholder;
            }.bind(this));
        }.bind(this));

        this.layers = [this.placeholders, this.letters, this.animations];
    },

    updatePlaceholders: function (columns) {
        var size = this.calcColumnsSize(columns, function (placeholder, x, y) {
            placeholder.setPosition(x + this.placeholderSize.width / 2, y + this.placeholderSize.height / 2);
        }.bind(this));

        this.layers.forEach(function (layer, layerIndex) {
            layer.forEach(function (row, i) {
                row.forEach(function (placeholder, j) {
                    if (layerIndex === cleverapps.Board.PLACEHOLDER_LAYER) {
                        placeholder.y = size.height - placeholder.y;
                    } else {
                        placeholder.setPosition(this.placeholders[i][j].getPosition());
                    }
                }.bind(this));
            }.bind(this));
        }.bind(this));

        this.setContentSize(size);
    },

    updateGrid: function (availableRect) {
        if (this.manualUpdateGrid) {
            return;
        }
        var columns = this.calculateColumns(availableRect);
        this.updatePlaceholders(columns);
    },

    setColumnsAmount: function (amountColumns) {
        this.manualUpdateGrid = true;

        var columns = this.divideByColumn(amountColumns);
        this.updatePlaceholders(columns);
    },

    calculateColumns: function (availableRect) {
        var optimalColumns = [];
        var maxScale = 0;

        for (var amountColumns = 1; amountColumns <= this.placeholders.length; amountColumns++) {
            var columns = this.divideByColumn(amountColumns);
            var size = this.calcColumnsSize(columns);

            var scale = Math.min(availableRect.width / size.width, availableRect.height / size.height);
            if (scale > maxScale) {
                optimalColumns = columns;
                maxScale = scale;
            }
        }

        return optimalColumns;
    },

    divideByColumn: function (amount) {
        var first = 0;
        var columnLength = 0;
        var columns = [];

        for (var i = 0; i < amount; i++) {
            columnLength = Math.floor(this.placeholders.length / amount);
            if (i < this.placeholders.length % amount) {
                columnLength++;
            }
            columns.push(this.placeholders.slice(first, first + columnLength));
            first += columnLength;
        }

        return columns;
    },

    calcColumnsSize: function (columns, iterator) {
        var styles = cleverapps.styles.BoardView;

        iterator = iterator || function () {};

        var letterOffset = styles.Letter.offset;
        var columnOffset = styles.minSpaceX;
        var rowOffset = styles.minSpaceY;

        var sizeX = 0;
        var sizeY = 0;

        columns.forEach(function (column, k) {
            var columnWidth = 0;
            var columnHeight = 0;

            for (var i = 0; i < column.length; i++) {
                var word = column[i];

                var wordWidth = 0;

                for (var j = 0; j < word.length; j++) {
                    iterator(word[j], sizeX + wordWidth, columnHeight);

                    wordWidth += this.placeholderSize.width + (j === word.length - 1 ? 0 : letterOffset);
                }

                columnWidth = Math.max(columnWidth, wordWidth);
                columnHeight += this.placeholderSize.height + (i === column.length - 1 ? 0 : rowOffset);
            }

            sizeX += columnWidth + (k === columns.length - 1 ? 0 : columnOffset);
            sizeY = Math.max(sizeY, columnHeight);
        }.bind(this));

        return cc.size(sizeX, sizeY);
    },

    getCell: function (row, col, layer) {
        layer = layer || cleverapps.Board.PLACEHOLDER_LAYER;

        return this.layers[layer][row][col];
    },

    putInCell: function (row, col, item, zOrder, layer) {
        layer = layer || cleverapps.Board.PLACEHOLDER_LAYER;

        var cell = this.layers[layer][row][col];
        cell.addChild(item, zOrder);
        item.setPosition(cell.width / 2, cell.height / 2);
    },

    isExistsCell: function (row, col) {
        return this.placeholders[row] && this.placeholders[row][col];
    },

    listCells: function (layer) {
        var activePlaceholders = [];
        for (var i = 0; i < this.placeholders.length; i++) {
            for (var j = 0; j < this.placeholders[i].length; j++) {
                switch (layer) {
                    case cleverapps.Board.PLACEHOLDER_LAYER:
                        activePlaceholders.push(this.placeholders[i][j]);
                        break;
                    case cleverapps.Board.LETTER_LAYER:
                        activePlaceholders.push(this.letters[i][j]);
                        break;
                    case cleverapps.Board.ANIMATION_LAYER:
                        activePlaceholders.push(this.animations[i][j]);
                        break;
                    default:
                        activePlaceholders.push(this.placeholders[i][j]);
                        activePlaceholders.push(this.letters[i][j]);
                        activePlaceholders.push(this.animations[i][j]);
                        break;
                }
            }
        }

        return activePlaceholders;
    },

    getColumn: function (index, layer) {
        layer = layer || cleverapps.Board.PLACEHOLDER_LAYER;

        return this.layers[layer][index];
    }
});