/**
 * Created by decipaliz on 03.08.2024
 */

var LevelsView = cc.Node.extend({
    ctor: function (page) {
        this._super();
        this.page = page;
    },

    onLoad: function () {
        if (this.page.isComingSoon()) {
            return;
        }

        var levelViews = [];
        for (var levelNo = 0; levelNo < Hose.LEVELS_PER_LOCATION; levelNo++) {
            var hoseLevel = new HoseLevel(this.page.pageNo, levelNo);
            levelViews.push(new LevelView(hoseLevel, this));
        }
        levelViews.reverse().forEach(this.addChild.bind(this));
    }
});

cleverapps.styles.LevelsView = {
    levels_1: undefined,
    levels_2: undefined
};