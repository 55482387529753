/**
 * Created by evgeny on 26.04.2024
 */

var Butterfly = function () {
    cleverapps.EventEmitter.call(this);
    cleverapps.Board.unguessedLetters.push(cleverapps.Board.BUTTERFLY);

    Game.currentGame.board.on("afterShowUp", this.showUp.bind(this));
    Game.currentGame.board.on("collect", this.collect.bind(this));
    Game.currentGame.board.on("beforeDiscover", this.setFilterForPositions.bind(this));
    Game.currentGame.board.on("hide", this.trigger.bind(this, "hideButterfly"));
};

Butterfly.prototype = Object.create(cleverapps.EventEmitter.prototype);
Butterfly.prototype.constructor = Butterfly;

Butterfly.prototype.setFilterForPositions = function (word) {
    this.wordLettersPositions = Game.currentGame.board.listWordIndexes(word);
};

Butterfly.prototype._choosePosition = function () {
    var positions = Game.currentGame.board.listEmptyUnguessedPositions();

    if (this.wordLettersPositions) {
        positions = positions.filter(function (position) {
            return !this.wordLettersPositions.find(function (letterPosition) {
                return position.row === letterPosition[0] && position.column === letterPosition[1];
            });
        }.bind(this));
    }

    if (positions.length) {
        return cleverapps.Random.choose(positions);
    }
};

Butterfly.prototype.createView = function () {
    new ButterflyView(this);
};

Butterfly.prototype.showUp = function (silent) {
    var position = this._choosePosition();

    if (position) {
        Game.currentGame.board.addState(position.row, position.column, cleverapps.Board.BUTTERFLY);
        this.createView();
        this.trigger("addButterfly", position.row, position.column, silent);
    }
};

Butterfly.prototype.collect = function (row, col) {
    if (Game.currentGame.board.guessedField[row][col] !== cleverapps.Board.BUTTERFLY) {
        return;
    }

    var position = this._choosePosition();

    var game = Game.currentGame;
    game.addClover(Mission.TYPE_BUTTERFLY, 1, true);

    if (!position) {
        game.counter.inc();
    }

    this.trigger("collectButterfly", row, col, function () {
        game.trigger("rewardClover");
        if (!position) {
            game.counter.dec();
        }
    });

    if (!position) {
        this.trigger("hideButterfly");
        return;
    }

    Game.currentGame.board.addState(position.row, position.column, cleverapps.Board.BUTTERFLY);
    cleverapps.eventLogger.logEvent(cleverapps.EVENTS.BUTTERFLY_COLLECTED);
    this.trigger("moveButterfly", position.row, position.column);
};

cleverapps.Board.BUTTERFLY = "$";