/**
 * Created by slava on 4/4/17.
 */

cleverapps.TutorialSteps = function () {

};

cleverapps.TutorialSteps.prototype.list = function (nextStepCallback) {
    var steps = [];

    for (var step = 0; step < this._getStepsNumber(); step++) {
        steps.push(this._regularStep.bind(this, nextStepCallback, step));
    }
    steps.push(this._finalStep.bind(this, nextStepCallback, step));

    return steps;
};

cleverapps.TutorialSteps.prototype.setGame = function (game) {
    this.game = game;
    this.game.tutorial = this;
};

cleverapps.TutorialSteps.prototype._getStepsNumber = function () {
    if (cleverapps.config.subtype === "stacks") {
        return 3;
    } 
    return 2;
};

cleverapps.TutorialSteps.prototype._pickWord = function (step) {
    if (cleverapps.config.subtype === "stacks") {
        return step >= Object.keys(this.game.board.foundWordsSet).length;
    }
    var word = this.game.board.words[this.game.board.words.length - step - 1];
    return !this.game.board.foundWordsSet[word] && word;
};

cleverapps.TutorialSteps.prototype._regularStep = function (nextStepCallback, step) {
    var word = this._pickWord(step);
    if (!word) {
        nextStepCallback();
        return;
    }

    this.game.keypad.tutorialWord(word);

    var handler = this.game.on("tutorialNext", function (discoveredWord) {
        if (word === discoveredWord || cleverapps.config.subtype === "stacks") {
            handler.clear();
            this.game.keypad.stop();
            nextStepCallback();
        }
    }.bind(this));
};

cleverapps.TutorialSteps.prototype._finalStep = function (nextStepCallback, step) {
    var word = this._pickWord(step);

    if (!word || cleverapps.config.regime === "knockout" || cleverapps.config.subtype === "stacks") {
        nextStepCallback();
        return;
    }

    this.createFinishTutorialMinimal();

    nextStepCallback();
};

cleverapps.TutorialSteps.prototype.createFinishTutorialMinimal = function () {
    this.createDialogue();
    this.running = true;
    var handler = function () {
        if (this.running) {
            this.dialogue.remove();
            this.dialogue = undefined;
            this.game.tutorial = undefined;
            this.running = false;
        }
    }.bind(this);

    this.game.keypad.once("touchBegan", handler);
    this.game.once("stop", handler);
};

cleverapps.TutorialSteps.prototype.createDialogue = function () {
    var scene = cleverapps.scenes.getRunningScene();
    this.dialogue = new MinimalDialogue({
        person: "king",
        delay: 2,
        text: Messages.get("TutorialFinishWindow.text", {
            gameTitle: Messages.get(cleverapps.config.title)
        }),
        control: "tutorialMinimalDialogue",
        forcePosition: cleverapps.styles.WordGameScene.minimalPosition
    });
    scene.addChild(this.dialogue);

    this.dialogue.display();
};

cleverapps.TutorialSteps.prototype.afterResize = function () {
    if (this.running) {
        this.dialogue && this.dialogue.hide(undefined, true);
        this.dialogue && this.dialogue.showUp();
    }
};