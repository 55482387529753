/**
 * Created by andrey on 19.01.18.
 */

var CryptexWindow = CleverappsWindow.extend({
    onWindowLoaded: function () {
        var styles = cleverapps.styles.CryptexWindow;

        var text = cleverapps.UI.generateTTFText("cryptexWindow.text", cleverapps.styles.FONTS.WINDOW_TEXT, {
            wordsCount: Cryptex.ATTEMPTS,
            maxTime: Math.floor(cleverapps.parseInterval(Cryptex.TIMEOUT) / 60000)
        });
        text.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);
        text.setDimensions(styles.width, 0);
        text.fitTo(undefined, styles.text.height);

        var openedCount = Game.currentGame.countDiscovered();

        var textProgressTitle = cleverapps.UI.generateTTFText("cryptexWindow.textProgress.Title", cleverapps.styles.FONTS.WINDOW_TEXT);
        textProgressTitle.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);
        textProgressTitle.setDimensions(styles.width, 0);
        textProgressTitle.fitTo(undefined, styles.textProgress.height);

        var icon = new cc.Node();
        icon.setAnchorPoint(0.5, 0.5);
        icon.setContentSize2(styles.animation);

        var animation = new cleverapps.Spine(bundles.cryptex.jsons.cryptex_json);
        animation.setAnimation(0, "idle", true);
        animation.setPositionRound(styles.animation);
        icon.addChild(animation);

        var progress = new ScaledProgressBar({
            progress: bundles.progress_bar.frames.bar_dailytask,
            background: bundles.progress_bar.frames.bg_dailytask,
            barText: {
                font: cleverapps.styles.FONTS.SMALL_WHITE_TEXT,
                text: openedCount + "/" + Cryptex.ATTEMPTS
            }
        });
        progress.setLength(styles.progress.width);
        progress.setGoal(Cryptex.ATTEMPTS);
        progress.setPercentage(openedCount);

        var content = new cleverapps.Layout([icon, text, textProgressTitle, progress], {
            direction: cleverapps.UI.VERTICAL,
            margin: styles.margin,
            padding: styles.padding
        });

        this._super({
            content: content,
            title: "cryptexWindow.title",
            name: "cryptexwindow",
            button: {
                text: "OK",
                width: styles.button.width
            },
            closeButton: false
        });
    }
});

cleverapps.styles.CryptexWindow = {
    width: 650,
    margin: 30,
    padding: { x: 0, y: 20 },

    animation: {
        width: 200,
        height: 300,
        x: { align: "center" },
        y: { align: "center" }
    },
    
    button: {
        width: 200
    },

    text: {
        height: 300
    },

    textProgress: {
        height: 100
    },

    progress: {
        width: 400
    }
};