/**
 * Created by evgeny on 26.04.2024
 */

var ButterflyView = cleverapps.Spine.extend({
    ctor: function (model) {
        this._super(bundles.butterfly_game.jsons.butterfly);
        this.setAnchorPoint(0.55, 0.6);
        this.setScale(1.7);

        this.grid = Game.currentGame.board.getView().grid;

        model.on("addButterfly", this.addButterfly.bind(this), this);
        model.on("moveButterfly", this.moveButterfly.bind(this), this);
        model.on("hideButterfly", this.hideButterfly.bind(this), this);
        model.on("collectButterfly", this.collectReward.bind(this), this);
    },

    onResize: function () {
        if (this.target) {
            this.stopAllActions();
            this.replaceParent(this.target);
            this.setAnimation(0, "idle", true);
            this.setPosition(cc.p(this.target.width / 2, this.target.height / 2));
            this.target = undefined;
            this.setScale(1.7);
        }
    },

    addButterfly: function (row, col, silent) {
        this.grid.putInCell(row, col, this, 6, cleverapps.Board.ANIMATION_LAYER);
        this.target = this.getParent();
        this.currentPosition = this.getPosition();
        if (!silent) {
            this.setAnimation(0, "show", false);
            cleverapps.audio.playSound(bundles.butterfly_game.urls.butterfly_open);
            this.setCompleteListenerOnce(function () {
                this.setAnimation(0, "idle", true);
            }.bind(this));
        } else {
            this.setAnimation(0, "idle", true);
        }
    },

    hideButterfly: function () {
        this.stopAllActions();
        this.clearTrack(0);
        this.setAnimation(0, "hide", false);
        cleverapps.audio.playSound(bundles.butterfly_game.urls.butterfly_close);
        this.setCompleteListenerOnce(this.removeFromParent.bind(this));
    },

    moveButterfly: function (row, col) {
        this.stopAllActions();
        var target = this.target = this.grid.getCell(row, col, cleverapps.Board.ANIMATION_LAYER);

        this.replaceParentSamePlace(cleverapps.scenes.getRunningScene().movingNode, {
            keepScale: true
        });

        var targetPosition = target.getPosition();
        targetPosition = this.getParent().convertToNodeSpace(target.getParent().convertToWorldSpace(targetPosition));

        this.setAnimation(0, "up", false);
        cleverapps.audio.playSound(bundles.butterfly_game.urls.butterfly_fly);
        this.setCompleteListenerOnce(function () {
            this.setAnimation(0, "fly", false);
            this.runAction(
                new cc.Sequence(
                    new cc.MoveTo(1.5, targetPosition),
                    new cc.ReplaceParent(target),
                    new cc.CallFunc(function () {
                        this.setAnimation(0, "idle", true);
                        cleverapps.audio.playSound(bundles.butterfly_game.urls.butterfly_land);
                    }.bind(this))
                )
            );
        }.bind(this));
    },

    collectReward: function (row, col, callback) {
        var icon = new cleverapps.Spine(bundles.butterfly_game.jsons.strawberry);
        cleverapps.scenes.getRunningScene().movingNode.addChild(icon);
        icon.setLocalZOrder(7);

        var cell = this.grid.getCell(row, col, cleverapps.Board.ANIMATION_LAYER);
        var cellPosition = cell.getPosition();
        cellPosition.y += cleverapps.styles.ButterflyView.rewardOffset;
        cellPosition = icon.getParent().convertToNodeSpace(cell.getParent().convertToWorldSpace(cellPosition));
        icon.setPosition(cellPosition);

        icon.runAction(new cc.Sequence(
            new cc.DelayTime(1),
            new cc.CallFunc(function () {
                icon.setAnimation(0, "animation", false);
            }),
            new cc.DelayTime(0.8),
            new cc.PlaySound(bundles.butterfly_game.urls.collect_effect)
        ));

        icon.setCompleteListenerOnce(function () {
            icon.runAction(new cc.Sequence(
                AnimationsLibrary.animateCollect(icon, "mission_reward" + Mission.TYPE_BUTTERFLY, {
                    duration: ButterflyView.FLIGHT_DURATION
                }),
                new cc.CallFunc(callback),
                new cc.RemoveSelf()
            ));

            icon.runAction(new cc.RepeatForever(
                new cc.RotateBy(0.5, 360)
            ));
        });
    }
});

cleverapps.styles.ButterflyView = {
    rewardOffset: 100
};

ButterflyView.FLIGHT_DURATION = 0.7;