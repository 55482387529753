cleverapps.crosswordFindWordIndexes = function (word) {
    cleverapps.Board.hintType = cleverapps.Board.HINT_TYPES.RANDOM;

    var listIndexes = function (startPoint, length, direction) {
        if (startPoint === undefined) {
            return [];
        }

        var res = [];
        var row = startPoint.row;
        var col = startPoint.col;
        for (var i = 0; i < length; i++) {
            res.push([row, col]);
            row += direction.row;
            col += direction.col;
        }
        return res;
    };

    var charByIndex = function (index) {
        if (index[0] < 0 || index[0] >= this.field.length
            || index[1] < 0 || index[1] >= this.field[index[0]].length) {
            return cleverapps.Board.EMPTY;
        }

        return this.field[index[0]][index[1]];
    };

    var startPoint = {
        row: 0,
        col: 0
    };

    var directions = [
        { row: 1, col: 0 },
        { row: 0, col: 1 }
    ];

    var length = word.length;
    for (var i = 0; i < directions.length; i++) {
        var dir = directions[i];
        var sm = {
            row: dir.row * (length + 1),
            col: dir.col * (length + 1)
        };
        for (startPoint.row = -1; startPoint.row <= this.field.length; startPoint.row++) {
            if (startPoint.row + sm.row > this.field.length || startPoint.row + sm.row < -1) {
                continue;
            }
            for (startPoint.col = -1; startPoint.col <= this.field[0].length; startPoint.col++) {
                if (startPoint.col + sm.col > this.field[0].length || startPoint.col + sm.col < -1) {
                    continue;
                }
                var indexes = listIndexes(startPoint, length + 2, dir);
                if ((cleverapps.Board.EMPTY + word + cleverapps.Board.EMPTY) === indexes.map(charByIndex, this).join("")) {
                    return indexes.slice(1, length + 1);
                }
            }
        }
    }

    return [];
};